import { Button as MUIButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const color1 = "#EF8933";
const color2 = "#202945";
const font1 = "Raleway, sans-serif";
const font2 = "Lato, sans-serif";


const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'custom' },
          style: {
            lineHeight: 1.25,
            backgroundColor: color1,
            color: '#FFFFFF',
            padding: '12px 15px',
            fontFamily: font2,
            fontSize: 21,
            fontWeight: 700,
            borderRadius: 5,
            textAlign: 'center',
            textDecoration: 'none',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: color2,
            },
          },
        },
        {
          props: { variant: 'prev' },
          style: {
            lineHeight: 1.25,
            backgroundColor: 'transparent',
            color: '#343333',
            padding: '12px 15px',
            fontFamily: font2,
            fontSize: 21,
            fontWeight: 700,
            borderRadius: 5,
            textAlign: 'center',
            textDecoration: 'none',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#E4E4E4',
            },
          },
        },
        {
          props: { variant: 'next' },
          style: {
            lineHeight: 1.25,
            backgroundColor: color1,
            color: '#343333',
            padding: '12px 15px',
            fontFamily: font2,
            fontSize: 21,
            fontWeight: 700,
            borderRadius: 5,
            textAlign: 'center',
            textDecoration: 'none',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: color2,
              color: '#FFFFFF',
            },
          },
        },
      ],
    },
  },
});


const Button = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <MUIButton onClick={props.onClick} variant={props.variant || 'custom'} disabled={props.disabled} className={props.className}>{props.children}</MUIButton>
    </ThemeProvider>
  )
}


export default Button;

