import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, IndexRoute } from "react-router-dom";
import TagManager from 'react-gtm-module';

import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import Header from './Components/Layout/Header/Header.jsx';
import Footer from './Components/Layout/Footer/Footer.jsx';
import LeftLogo from './Components/Layout/LeftLogo';
import Start from './Pages/Start/Start';
import Assessment from './Pages/Assessment';
import AssessmentV3 from './Pages/Assessment/Assessment';
import AssessmentAdditional from './Pages/AssessmentAdditional';
import Details from './Pages/Details/Details';
import Results from './Pages/Results';
import Dashboard from './Pages/Dashboard';
import Invite from './Pages/Invite/Invite';
import InviteThankYou from './Pages/InviteThankYou';
import PreAdditionalInfo from './Pages/PreAdditionalInfo';
import PreAdditionalQuestions from './Pages/PreAdditionalQuestions';
import PreAssessment from './Pages/PreAssessment/PreAssessment.jsx';
// import { AssessmentProgressContextProvider } from './Store/AssessmentProgressContext';
// import AssessmentProgressSections from './Components/AssessmentProgressSections';

import { PreAdditionalQuestionContextProvider } from './Store/PreAdditionalQuestionContext';
import { AdditionalQuestionContextProvider } from './Store/AdditionalQuestionContext';
import { AssessmentQuestionContextProvider } from './Store/AssessmentQuestionContext';
import { QuestionsProgressContextProvider } from './Store/QuestionsProgressContext';
import DonutChart from './Components/DonutChart';
import Intro from './Pages/Intro/Intro.jsx';
import Outro from './Pages/Outro/Outro.jsx';
import FirstForm from './Pages/FirstForm';
import Charts from './Pages/Chart.jsx';
import AssessmentAdditionalV3 from './Pages/AssessmentAdditional/AssessmentAdditional';
import MultipleResults from './Pages/MultipleResults.jsx';

import './App.scss';
import { InitialTransition } from './Components/Animation/InitialTransition.jsx';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#ff9440',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#b95f19',
  },
}));


// ReactGA.initialize('');
// ReactGA.pageview(window.location.pathname + window.location.search);

const tagManagerArgs = {
  gtmId: 'GTM-M2HCJJHK'
}

TagManager.initialize(tagManagerArgs)

function App() {

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [contentBlocks, setContentBlocks] = useState([]);
  const [bodyBlock, setBodyBlock] = useState([]);

  useEffect(() => {
    // getAssessmentContent();
  }, []);

  const getAssessmentContent = () => {

    setIsLoading(true);
    let url = window.location.href.replace("http://", "");
    url = url.replace("https://", "");
    let path = url.split("/");

    let urlId = "";
    if(path[0].includes("localhost")) {
      let devPath = path[0].split(":");
      urlId = (parseInt(devPath[1]) - 3000).toString();
    } else {
      
      if(url.includes("preview=")) {
        console.log("We have it");
        urlId = url.split("preview=");
        urlId = urlId[1].split("&");
        urlId = urlId[0].toString();
      }
      // else urlId = "49";
      else urlId = process.env.REACT_APP_CLIENT_ID;

    }
    
    console.log(urlId);
    // fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + urlId + '/byUrl/' + process.env.REACT_APP_URL_FIELD, {
    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + urlId + '/content', {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
      console.log(data);
      
			if(data.status == "success") {
        if(path[1].includes('start')) {
          console.log("Are we here");
          setContentBlocks(data.content.filter(obj => obj.page == "start"));
        }
        setBodyBlock(contentBlocks.filter(obj => obj.pageArea == "background"))
        setIsLoading(false);
        console.log(contentBlocks);
        console.log(bodyBlock);
			} else {
				// setOpenSnackbar(true);
			}
		}).catch((err) => {
      console.error("Failed to fetch assessment content...", err);
    });
    
  }

  if (isLoading) {
    return <div className="loading"><div style={{width: "400px"}}><BorderLinearProgress value={50} /></div></div>
  }

  return (
    <div className="App">
      {/* <AssessmentProgressContextProvider> */}
      <BrowserRouter>
      {/* { <InitialTransition /> } */}
      { 
        isLoading ? <div className="container details loading"><CircularProgress style={{ color: "#EF8933" }} size={60} /></div> : <AssessmentQuestionContextProvider>
          <AdditionalQuestionContextProvider>
            <PreAdditionalQuestionContextProvider>
              <QuestionsProgressContextProvider>
                <Header />
                <main className="main">
                  {/* <LeftLogo/> */}
                  {/* <AssessmentProgressSections /> */}
                  <Routes>
                    <Route path="/" exact index element={<Start setName={setName} />} />
                    <Route path="/start" exact element={<Start setName={setName} />}></Route>
                    {/* <Route path="/before-you-start" exact element={<PreAssessment />}></Route> */}
                    <Route path="/before-you-start/:id" exact element={<PreAssessment />}></Route>
                    {/* <Route path="/assessment" exact element={<Assessment />}></Route> */}
                    <Route path="/assessment/:id/:pageId" exact element={<AssessmentV3 />}></Route>
                    {/* <Route path="/assessment/:id/review" exact element={<Assessment />}></Route> */}
                    {/* <Route path="/assessment/:id/:pageId/review" exact element={<AssessmentV3 />}></Route> */}
                    <Route path="/pre-additional-info/:id" exact element={<PreAdditionalInfo />}></Route>
                    <Route path="/pre-additional-questions/" exact element={<PreAdditionalQuestions />}></Route>
                    {/* <Route path="/pre-additional-questions/:id" exact element={<PreAdditionalQuestions />}></Route> */}
                    {/* <Route path="/assessment-additional/:id" exact element={<AssessmentAdditional />}></Route> */}
                    {/* <Route path="/assessment-additional/:id/:pageId" exact element={<AssessmentAdditional />}></Route> */}
                    <Route path="/assessment-additional/:id/:pageId" exact element={<AssessmentAdditionalV3 />}></Route>
                    <Route path="/details/:id" exact element={<Details />}></Route>
                    <Route path="/results/:id" exact element={<Results />}></Route>
                    <Route path="/invite-team/:id" exact element={<Invite />}></Route>
                    <Route path="/invite-team-thanks/:id" exact element={<InviteThankYou />}></Route>
                    {/* <Route path="/dashboard/:id" exact element={<Dashboard />}></Route> */}
                    <Route path="/dashboard/:id" exact element={ <MultipleResults/> }></Route>
                    <Route path="/chart/:id/:pageId" exact element={<DonutChart />}></Route>
                    {/* <Route path="/chart" exact element={<DonutChart/>}></Route> */}
                    <Route path="/intro/:id/:pageId" exact element={<Intro />}></Route>
                    <Route path="/intro" exact element={<Intro />}></Route>
                    <Route path="/first-form/:id" exact element={<FirstForm />}></Route>
                    <Route path="/first-form" exact element={<FirstForm />}></Route>
                    <Route path="/charts/:id/:pageId" exact element={<Charts />}></Route>
                    <Route path="/outro/:id/:pageId" exact element={<Outro />}></Route>
                   

                  </Routes>
                </main>
                <Footer />
              </QuestionsProgressContextProvider>
            </PreAdditionalQuestionContextProvider>
          </AdditionalQuestionContextProvider>
        </AssessmentQuestionContextProvider>
      }
      </BrowserRouter>
      {/* </AssessmentProgressContextProvider> */}
    </div>
  );
}

export default App;
