import React from 'react';
import { Link } from "react-router-dom";

// import Logo from '../../Assets/Images/logo.png';
import logoLeft from '../../../Assets/Images/logo-left.svg';
// import logoLeftMobile from '../../../Assets/Images/leftlogomob.png';
import logoLeftMobile from '../../../Assets/Images/logo-left-mobile.png';
// import bleatLogo from '../../../Assets/Images/logo-bleat-desktop.png';
// import bleatLogo from '../../../Assets/Images/assessment-logo-right.svg';
import bleatLogo from '../../../Assets/Images/Bleatlogo.png';
import bleatLogoMobile from '../../../Assets/Images/bleat-logo-mobile.png';
import useWindowDimensions from '../../../Hooks/useWindowDimensions';
import assessmentTitle from '../../../Assets/Images/assessment-name.png'
import { motion } from 'framer-motion';

const Header = props => {

  const { windowWidth } = useWindowDimensions();

  const logoLeftBox = {
    initial: {
      position: "relative",
      top: "15px",
      opacity: 0
    },
    animate: {
      top: 0,
      opacity: 1,
      transition: {
        delay: 1.2,
        duration: 0.8,
        ease: [.175,.885,.32,1.275],
      },
    },
  };

  const middleBox = {
    initial: {
      position: "relative",
      top: "15px",
      opacity: 0
    },
    animate: {
      top: 0,
      opacity: 1,
      transition: {
        delay: 1.6,
        duration: 0.8,
        ease: [.175,.885,.32,1.275],
      },
    },
  };

  const rightLogoBox = {
    initial: {
      position: "relative",
      top: "15px",
      opacity: 0
    },
    animate: {
      top: 0,
      opacity: 1,
      transition: {
        delay: 2,
        duration: 0.8,
        ease: [.175,.885,.32,1.275],
      },
    },
  };

  return (
    <>
      <header>
        <div>
        <div className="header-wrapper">
        
            {/* {windowWidth > 830 ?  */}
            {windowWidth > 650 ?
            <motion.div className="logo-left" initial="initial" animate="animate" variants={logoLeftBox}>
            <img src={logoLeft} alt="Logo" />
            </motion.div>
            :  <motion.div className="logo-left" initial="initial" animate="animate" variants={logoLeftBox}>
                <img src={logoLeftMobile} alt="Logo" />
              </motion.div>}

          {/* {windowWidth > 830 ?  */}
          <motion.div className="assessment-title desktop" initial="initial" animate="animate" variants={middleBox} id="assessment-title">
            <p className="logo-text"><span>7 Proven Practices</span></p>
            <p className="logo-subtext">Assessment</p>
          </motion.div>

          {/* {windowWidth > 830 ?  */}
          <motion.div className="logo-right" initial="initial" animate="animate" variants={rightLogoBox} style={{}} >
            {/* <img src={bleatLogo} width="auto" height={67.5} alt="Bleat" /> */}
          </motion.div>

        </div>
        </div>

      </header>
    </>
  )
}

export default Header;
