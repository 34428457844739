import React from 'react'
import useWindowDimensions from '../Hooks/useWindowDimensions';
import shadowIcon from '../Assets/Images/shadow.svg'
import { CircleTwoTone, WbSunnyOutlined } from "@mui/icons-material";

export default function HorizontalBarCharts(props) {

  const windowWidth = useWindowDimensions()

  const getProgreebarColor = (index) => {
    if( index == 0 ) {
      return '#6183b4';
    }
    else if( index == 1 ) {
      return '#a35a53';
    }
    else if( index == 2 ) {
      return '#a6b56f';
    }
    else if( index == 3 ) {
      return '#756998';
    }
    else{
      return '#74a8be';
    }
  }

  const getLeftMargin = () => {
    if (windowWidth < 651 && props.score > 97) {
      return { left: 98 + "%" };
    } else if (windowWidth < 651 && props.score < 3) {
      return { left: 2 + "%" };
    } else {
      return { left: Math.round(props.score) + "%" };
    }
    // return { left: Math.round(score) + "%" };
  };

  const getProgressLinearBar = () => {
    return props.sectionsAndSubScores?.map((el,index)=>{
      const scoreName = el?.section;
      return <div className="progressbar-container">
        <p className="progressbar-score-name">{scoreName}</p>
        <div className="progress-linear-bar">
          <div className="si_ass_result" style={{ position: "relative" }}>
            <div className="progress-linear-opacity-filter" style={{ width: "100%" }}>
              <div className="transparent" style={{ width: `${Math.round(el?.score)}%`, height: "26px", background: getProgreebarColor(index)}}>
              <span className="msi_score" style={getLeftMargin()}>{Math.round(el?.score)}%</span>
              </div>
              <div className="opaque" style={{ width: `${(100 - Math.round(el?.score))}%`, height: "26px", background: `#e9e9e9` }}></div>
            </div>
          </div>
        </div>
        <div className="progressbar-icon-and-section-name">
          { getProgressBarIcon(el) }
        </div>
      </div>
    })
  }

  const getProgressBarIcon = (item) => {
    if ( item?.score <= 30 ) {
       return <>
        <img src={shadowIcon} />
        <div className="section-name">Shadow</div>
       </>
    }
    else if ( item?.score > 30 &&  item?.score <= 75 ){
      return <>
        <CircleTwoTone/>
        <div className="section-name">Middle</div>
      </>
    }
    else {
      return <>
         <WbSunnyOutlined/>
         <div className="section-name">Sunlight</div>
      </>
    }
  }

  return (
    <div className='progress-bar-content-container'>{getProgressLinearBar()}</div>
  )
}
