import { useState, useEffect, useContext } from "react";

import { Button } from "@mui/material";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import AssessmentProgressContext from "../../Store/AssessmentProgressContext";
import img1 from "../../Assets/Images/img1.png";
import img2 from "../../Assets/Images/img2.png";
import img3 from "../../Assets/Images/img3.png";
import img4 from "../../Assets/Images/img4.png";
import img5 from "../../Assets/Images/img5.png";

const data = [
  { id: 1, img: img1, name: "Mentor", 
    text: ["Mentors come alongside of you, help you realize your potential, and fan the flame of your ability.", 
    "As trusted allies, they are friendly faces who encourage you to grow, develop and change."]
  },
  { id: 2, img: img2, name: "Administrator", 
    text: ["Administrators help establish the plan and then follow it as it is laid out.", 
    "They are good with details, procedures and budgets. Administrators focus on management and tend to be averse to taking risks."]
  },
  { id: 3, img: img3, name: "Catalyst", 
    text: ["Catalysts challenge you, and so take you to new ground. They innovate and experiment in life.", 
    "As trusted critics, Catalysts seem to light fires under people, spurring them on to unconventional or entirely new methods."]
  },
  { id: 4, img: img4, name: "Relational", 
    text: ["Relationals are motivated by harmony and are good team builders. They highly value grace and mercy and can be quite loving and warm.", 
    "Relationals’ decisions are guided by deep-seated values and personal connections to others."]
  },
  { id: 5, img: img5, name: "Overseer", 
    text: ["Overseers see the big picture and think about the vision and direction of things. They work best with principles and concepts as their guiding force.", 
    "Overseers prefer working on strategy and aligning people with their right places to work."]
  },
];

const PreAssessment = () => {

  const assessProgressCtx = useContext(AssessmentProgressContext);

  const { windowWidth }                   = useWindowDimensions();
  const { id }                            = useParams();

  const [searchParams, setSearchParams]   = useSearchParams();
  let navigate                            = useNavigate();
  const location                          = useLocation();
  const [pathname, setPathname]           = useState();

  useEffect(() => {
		// document.body.style.backgroundColor = "#343333";
    // assessProgressCtx.updateMainSectionsStatus(assessProgressCtx?.mainSections?.map(sec => ({ ...sec, status: "unfinished" })));
	},[]);

  useEffect(() => {
		console.log('location',location)
    setPathname(location.pathname)
	},[location]);
  
  const assessmentLink = () => {
    // if(!searchParams) return "/assessment";
    // else return "/assessment?" + searchParams;
    const pageId = 743; // TODO: update this number according to page ID for questions
    navigate("/assessment/" + id + "/" + pageId + "?" + searchParams, { replace: true });
  }

  return (
    <div className="container pre-assessment" style={{ background: pathname?.includes('before-you-start') ? "#fff" : '#CCCC0021' }}>

      <h1>
        The 5 scales on the Executive Indicator show your strengths 
        <br />
        in the Mentor, Administrator, Catalyst, Relational, and Overseer patterns.
      </h1>
      {/*
      <p className="pre-assessment-subheader">For each of the following 21 church benchmarks, choose one of these three responses that accurately reflects your church's current situation.</p>
      <div className="explanation">
      <div className="marker red"></div><p>The benchmark is not in position to activate the Great Commission or multiply Kingdom impact. It is potentially broken or barely functioning to these standards, and demands attention.</p>
      </div>
      <div className="explanation">
      <div className="marker yellow"></div><p>The benchmark affects Great Commission activation and multiplies Kingdom impact but has room for improvement. It is not at its top level of effectiveness, and needs further action to become optimal.</p>
      </div>
      <div className="explanation">
      <div className="marker green"></div><p>The benchmark activates the Great Commission and multiplies Kingdom impact. It is effective and healthy, but might need slight improvement. Ensure it has the resources and attention it needs.</p>
      </div>*/}
      
      <div className="img-text-row">
        {data.map((item, idx) => (
          <ImageTextContainer key={idx} {...item} />
        ))}
      </div>




      {/* <p>Answer ten questions about each aspect to check if your ministry is drifting today.</p> */}
      {/* <Link to={assessmentLink()} className="cta-link"> */}
        <Button className="cta" onClick={() => assessmentLink()}>Take the Indicator</Button>
      {/* </Link> */}
      
    </div>
  )
}


export default PreAssessment;



const ImageTextContainer = ({ name, text, img }) => {
  
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="img-text-wrapper"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? (
        <>
        <div className="text">
          {text?.map((txt, idx) => {
            return <p key={idx}>{txt}</p>
          })}
        </div>
        <p className="img-label">{name}</p>
        </>
      ) : (
        <>
          <div className="img">
            <img src={img} alt={name} />
          </div>
          <p className="img-label">{name}</p>
        </>
      )}
    </div>
  );
};

