import { useState, useEffect, useRef } from 'react';
import ReactSelect from 'react-select';
import { Slider, LinearProgress, TextField, Radio, RadioGroup, FormControlLabel, Checkbox, MenuItem, Select } from '@mui/material';
import { CheckCircleRounded, ExpandMore } from '@mui/icons-material';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import dropdownArrow from '../Assets/Images/dropdown-arrow.svg'

const Question = props => {
  
  console.log("Question props", props);
  // console.log("Question", props?.question?.question);
  
  const { windowWidth }               = useWindowDimensions();
  const [showSlider, setShowSlider]   = useState(false);
  const [progress, setProgress]       = useState(props?.currentValue);
  const [sliderWidth, setSliderWidth] = useState(90);
  const [maxWidth, setMaxWidth]       = useState(props.question?.answers?.length);
  const [runTimeoutFunc, setRunTimeoutFunc] = useState(false);
  const [month, setMonth]             = useState("")
  const [year, setYear]               = useState("")
  const [textAns, setTextAns]         = useState(props.question?.answerTxt);
  const [selectedAnswers, setSelectedAnswers] = useState();
  const [notApplicable, setNotApplicable] = useState(false);

  const isSelected = (ans) => selectedAnswers.includes(ans);
  const months = [
    { name: "January",    number: "01" },
    { name: "February",   number: "02" },
    { name: "March",      number: "03" },
    { name: "April",      number: "04" },
    { name: "May",        number: "05" },
    { name: "June",       number: "06" },
    { name: "July",       number: "07" },
    { name: "August",     number: "08" },
    { name: "September",  number: "09" },
    { name: "October",    number: "10" },
    { name: "November",   number: "11" },
    { name: "December",   number: "12" }
  ];

  const years = Array.from({ length: 2024 - 1995 + 1 }, (_, index) => ({
    year: 1995 + index
  }));

  useEffect(() => {
    if(props.question.answerType == "slider") {
      if((props.question?.answer == 0 || !props.question?.answer) && !progress) {
        setShowSlider(false);
      } else if(props.question?.answer > 0 || progress > 0) {
        setShowSlider(true);
      }
    }
    if(props.question.answerType == "multiple_choice") {
      console.log(props.question?.answer)
      let selAnswer = props.question.answers.find(obj => obj.value == props.question.answer );
      console.log(selAnswer);
      if(selAnswer) {
        setTimeout(() => {setSelectedAnswers(selAnswer.desktopText);}, 100);
      }
    } else {
      if(!props.question?.answerTxt || props.question?.answerTxt == "") {
        setTextAns("");
      } else {
        setTextAns(props.question?.answerTxt);
      }
    }
    
  }, [props.question.answer, progress, props.question.answerType, props.question.answers, props.question?.answerTxt])
  
  // empty text area on question change
  useEffect(() => {
    if(!props.question?.answerTxt || props.question?.answerTxt == "") {
      setTextAns("");
    } else {
      setTextAns(props.question?.answerTxt);
    }
    
  }, [props.question]);

  // useEffect(() => {
  //   if(runTimeoutFunc) { 
  //     const timer = setTimeout(() => {
  //       if(props.question?.answer == 0 || !props.question.answer) {
  //         setShowSlider(false);
  //       }
  //     }, 500);
  //     return () => {
  //       setRunTimeoutFunc(false);
  //       clearTimeout(timer);
  //     }
  //   }

  // }, [props.question?.answer, runTimeoutFunc]);

  // console.log("showSlider", showSlider);

  

  useEffect(() => {
    if (props.question?.answer == 0 || !props.question?.answer) setProgress(0);
    // setProgress(props.question.answer);
    else if (props.question?.answer > 0) {
      const findAnsOption = props.question?.answers?.find((ans) => ans?.value === props?.question?.answer);
      setProgress(findAnsOption?.orderNo);
    }
  }, [props.question]);

  useEffect(() => {
    switch (props.question?.answers?.length) {
      case 2: setSliderWidth(36); break;
      case 3: setSliderWidth(56); break;
      case 4: setSliderWidth(76); break;
      case 5: setSliderWidth(85); break;
      case 6: setSliderWidth(90); break;
      default: break;
    }
    
    setMaxWidth(props.question?.answers?.length);


  }, [props, props.question?.question, props.question?.answer])

  // console.log("props.question.answer", props.question.answer);
  // console.log("progress", progress); console.log("showSlider", showSlider);

  // useEffect(() => {
  //   const timer = () => setTimeout(() => {
  //     if(selectedAnswers.length > 0){
  //       props.questionAnswered(selectedAnswers);
  //       setSelectedAnswers([]);
  //     }
  //   }, 2000);
  //   clearTimeout(timer());
  //   if(selectedAnswers && selectedAnswers.length > 0){
  //     timer();
  //   }
  // }, [selectedAnswers])


  const getLeft = (index) => {
    switch (props.question?.answers?.length) {
      case 2: return index * 80;
      case 3: return index * 40;
      case 4: return index * 27;
      case 5: return index * 20;
      case 6: return index * 16;
      default: return index * 20;
    }
  }
  
  const getLeftRadio = (index) => {
    switch (props.question?.answers?.length) {
      case 2: return index * 80;
      case 3: return index * 40;
      case 4: return index * 27;
      case 5: return index * 6;
      case 6: return index * 4;
      default: return index * 20;
    }
  }

  const getRadioWidth = (answers) => {

    switch (answers) {
      // case 3: return {width: "70%", marginLeft: "7.5%"};
      case 3: return { width: windowWidth > 1000 ? "75%" : windowWidth > 900 ? "75%" : windowWidth < 651 && windowWidth > 550 ? "90%" : windowWidth < 551 ? "95%" : "90%" };
      // case 4: return { width: "75%", margin: "0 auto" };
      
      case 4: return { width: windowWidth < 551 ? "95%" : windowWidth < 651 ? "90%" : windowWidth < 831 ? "80%" : "45ch", margin: "0 auto" };
      case 5: return { width: "90%", marginLeft: "2.5%" };
      case 6: return { width: "100%", marginLeft: "0%" };
      default: return { width: "50%" };
    }
    // if(answers === 3) { return {width: "70%", marginLeft: "7.5%"}; }
    // return {width: "50%"};
  }

  const radioOptionWidth = (answers) => {
    switch (answers) {
      case 3: return "90%";
      // case 4: return "90%";
      case 4: return "100%";
      case 5: return "20%";
      case 6: return "16%";
      default: return "20%";
    }
  }

  // console.log("progress", progress);
  const updateStep = (ansVal, orderNo) => {
    console.log("\n\n\nansVal", ansVal);
    console.log("orderNo", orderNo, "\n\n\n");
    setShowSlider(true);
    // setTimeout(() => {setProgress(progressArg)}, 0);
    // props.questionAnswered(progressArg);
    setTimeout(() => {setProgress(orderNo)}, 0);
    // setTimeout(() => {setProgress(ansVal)}, 0);
    props.questionAnswered(ansVal);
    // setRunTimeoutFunc(true);
  }

  const updateDropDownStep = (ansVal, orderNo) => {
    // console.log(ansVal);
    // console.log(orderNo);
    // console.log(props);
    props.questionAnswered(ansVal);
    setTimeout(() => {setProgress(orderNo)}, 0);
  }

  const updateRadioStep = (ansVal, orderNo) => {

    setTimeout(() => {setProgress(orderNo)}, 0);
    props.questionAnswered(ansVal);
    setTimeout(() => {
      // setProgress(0);
    }, 500);

  }

  const getLabelStyle = () => {

    // console.log("props.question", props.question);
    if(window.innerWidth > 650) {

      if(props.question?.answers?.length === 5) {
        // return { width: "100%", marginLeft: "-50%" };
        return { width: "14ch", marginLeft: "-7ch" };
      } else if(props.question?.answers?.length === 4) {
        return { width: "95%" };
      } else if(props.question?.answers?.length === 3 && props.question?.answerType === "radio") {
        // return { width: "200%", marginLeft: "-100%" };
        // return { width: "25ch" };
      } else if(props.question?.answers?.length === 2) {
        return { width: "400%", marginLeft: "-200%" };
      } else {
        return { width: "150%", marginLeft: "-75%" };
      }
      // return { width: "14ch", marginLeft: "-7ch" };

    } else {

      if(props.question?.answers?.length === 6) {
        return { width: "14ch", marginLeft: "-7ch", wordBreak: "break-all" };
      }
      // if(props.question?.answers?.length === 5) {
      //   return { width: "120%", marginLeft: "-60%" };
      // } else if(props.question?.answers?.length === 4) {
      //   return { width: "150%", marginLeft: "-75%" };
      // } else if(props.question?.answers?.length === 3) {
      //   return { width: "300%", marginLeft: "-150%" };
      // } else if(props.question?.answers?.length === 2) {
      //   return { width: "500%", marginLeft: "-250%" };
      // } else {
      //   return { width: "150%", marginLeft: "-75%" };
      // }
      // else return { width: "12ch", marginLeft: "-6ch" };
      else if (props?.question?.answerType === "slider") {
        // return { width: "9ch", marginLeft: "-4.5ch" };
        // return { width: "7ch", marginLeft: "-3.5ch" };
        return { width: "10ch", marginLeft: "-5ch" };
      } else return { width: "auto" };
    }
    
  }

  const checkOpacity = (index) => {
    if(index === 0 && showSlider) return "0";
    else return "1";
  }

  const checkSelected = (optionOrderNo) => {
  // const checkSelected = (data, value) => {
    // console.log("checkSelected data:", data, "value:", value);
    // if(progress === value) {
    if(progress && (Number(progress) === Number(optionOrderNo))) {
      // console.log("desktopText", data.desktopText);
      return "label active";
    }
    else return "label";
  }

  const updateSliderTextHighlight = (progress) => {
    console.log("progress", progress);
    console.log("desktopText", props?.question?.answers?.find(ans => ans.value === progress)?.desktopText);
    let activeSlider = null;
    // let sliderText = props?.question?.answers?.find(ans => ans.value === progress)?.desktopText;
    let sliderText = props?.question?.answers?.find(ans => ans.value === progress)?.desktopText;
    switch (sliderText?.trim()) {
      case "Strongly Disgree":
        activeSlider = 1;
        break;
      case "Strongly<br/>Disgree":
        activeSlider = 1;
        break;
      case "Disagree":
        activeSlider = 2;
        break;
      case "Neutral":
        activeSlider = progress;
        break;
      case "Agree":
        activeSlider = 4;
        break;
      case "Strongly Agree":
        activeSlider = 5;
        break;
      case "Strongly<br/>Agree":
        activeSlider = 5;
        break;
      default: 
        activeSlider = progress;
    }
    return activeSlider;
  }  

  const updateQuestion = (event, step) => {
    // props.question.answerTxt = event.target.value;
    setTextAns(event.target.value);
    // setState({answerTxt: event.target.value});
    props.updateTextAnswer(props.question?.id, event.target.value);
    // return true;
  }

  const updateMonthYear = (month, year) => {
    if (month.number) setMonth(month);
    if (year) setYear(year);
    if (month.number && year) {
      let monthYear = year + "-" + month.number;
      // props.updateTextAnswer(props.question?.id, monthYear);
      props.updateTextAnswerAndMoveToNextQuestion(props.question?.id, monthYear, -1, true);
    }
    // if (month == "00" && year == "0000") {
    //   let monthYear = year + "-" + month;
    //   props.updateTextAnswer(props.question?.id, monthYear);
    // }
  }

  const handleMultipleAnswer = (ansval, anstext) => {
    props.questionAnswered(ansval);
    setSelectedAnswers(anstext);
    setTimeout(() => {
      setSelectedAnswers("");
    }, 500);
  };


  // console.log(selectedAnswers)
  const givePlaceholder = (queId) => {
    if (queId == 1197) {
      return "Please type your response here";
    } else {
      return "Please type your response here";
    }
  }

  const classes = {
    menulist: "month-select"
  }

  const notApplicableQuestions = [1192, 1193, 1196];


    return (
      <div>
        
        <div className={`question`} style={{ minHeight: props?.question?.answerType === "radio" ? "140px" : "130px" }}>
          <p style={{ maxWidth: "65ch" }} dangerouslySetInnerHTML={{__html: props.question?.question}}></p>
        </div>

        {
          (() => {
            // console.log('answerType',props.question?.answerType)
            switch(props.question?.answerType) {
              case "textarea":
                return <>
                  <div className="textarea-field">
                    {/* <TextField hiddenLabel multiline rows={windowWidth > 650 ? 4 : 6} placeholder="Please type your response here" value={props.question?.answerTxt} onChange={(e) => {updateQuestion(e, 1)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" /> */}
                    <TextField hiddenLabel multiline rows={windowWidth > 650 ? 4 : 6} placeholder={givePlaceholder(props?.question?.id)} value={textAns} onChange={(e) => {updateQuestion(e, 1)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                  </div>
                </>

              case "radio":
                return <>
                  <div className="radio-container">
                    <div className="slider-labels" style={getRadioWidth(props.question?.answers?.length)}>
                      { 
                        props.question?.answers?.map((data, index) => (
                          // were using checkSelected(data.value) here before this change to orderNo
                          <div className={checkSelected(data.orderNo)} key={`labels-${index}`} style={{ left: getLeftRadio(index) + '%', width: radioOptionWidth(props?.question?.answers?.length), display: "flex", cursor: "pointer" }} onClick={() => {
                            console.log("data.value", data.value);
                            updateRadioStep(data.value, data.orderNo);
                          }}>
                          {/* <div className={checkSelected(data, data.value)} key={`labels-${index}`} style={{ left: getLeft(index) + '%'}} onClick={() => updateStep(data.value)}> */}
                            {/* <FormControlLabel value={data.value} control={<Radio />} label="" /> */}
                            <Radio checked={data.value === progress} value={progress} sx={{ '&, &.Mui-checked': { color: '#343333', }, }} />
                            <p className="desktop" style={getLabelStyle()}>{data.desktopText}</p>
                            <p className="mobile" style={getLabelStyle()}>{data.desktopText}</p>
                          </div>
                        ))
                      }
                          {/* { 
                            props.question?.answers.map((data, index) => (
                              <FormControlLabel value={data.value} control={<Radio />} label={data.value} onClick={() => updateStep(data.value)} />
                              // <a className="point" key={`points-${index}`} style={{ left: getLeft(index) + '%', opacity: checkOpacity(index) }}></a>
                            ))
                          } */}
                      
                    </div>
                  </div>
                </>

              case "multiple_choice":
                return <>
                  <div className="multiple-choice-container" >
                    <div className="multiple-choice">
                      {/* TODO: add multiple choice type options */}
                      {props.question?.answers?.map((ans, idx) => {
                        // console.log("ans",ans)
                        return <div key={idx} className={`answer ${selectedAnswers == ans.desktopText ? 'selected' : ''}`} onClick={() => handleMultipleAnswer(ans.value, ans.desktopText)}>
                          {selectedAnswers === ans.desktopText ? <CheckCircleRounded className="tick-icon" /> : <p style={{ marginLeft: "40.99px" }}></p>}
                          <span className="answer-text">  {ans.desktopText}</span>
                        </div>
                      })}
                    </div>
                  </div>
                  {notApplicableQuestions.includes(props.question?.id) ?
                    <div className="not-applicable-container">
                      <SkipQuestionCheckbox key={props.question?.id} skipQuestionFunction={props.questionAnswered} question={props.question} setSelectedAnswers={setSelectedAnswers} />
                    </div>
                    : null
                  }
                </>

              case "month_year":
                return <div className="month-year-container">
                  <div className="month-year-select-container">
                    <div className="month-container">
                      <p className="month-header">Month</p>
                      <Select id="month-select" className={`month-select ${!notApplicable ? 'border-selected' : ''}`} disabled={props.question?.answer == 0 ? true : false}
                        displayEmpty renderValue={(value) => (value !== undefined ? value : "January")} 
                        IconComponent={(props) => (<ExpandMore {...props} />)}
                        MenuProps={{ classes: { paper: classes.menulist } }}
                        value={month.name} onChange={(e) => updateMonthYear(months.find((m) => m.name === e.target.value), year)}
                      >
                        {months?.map((el, idx) => {
                          return <MenuItem key={idx} value={el?.name}>{el?.name}</MenuItem>
                        })}
                      </Select>
                    </div>
                    <div className="year-container">
                      <p className="year-header">Year</p>
                      <Select id="year-select" className={`month-select year-select ${!notApplicable ? 'border-selected' : ''}`} 
                        disabled={props.question?.answer == 0 ? true : false}
                        displayEmpty renderValue={(value) => (value !== "" ? value : "2015")} 
                        IconComponent={(props) => (<ExpandMore {...props} />)}
                        MenuProps={{ classes: { paper: classes.menulist } }}
                        value={year} onChange={(e) => updateMonthYear(month, e.target.value)}
                      >
                        {years?.map((el, idx) => {
                          return <MenuItem key={idx} value={el?.year}>{el?.year}</MenuItem>
                        })}
                      </Select>
                    </div>
                  </div>

                  {notApplicableQuestions.includes(props.question?.id) ?
                    <div className="not-applicable-container">
                      <SkipQuestionCheckbox key={props.question?.id} skipQuestionFunction={props.questionAnswered} question={props.question} setSelectedAnswers={setSelectedAnswers} updateMonthYear={updateMonthYear} updateTextAnswerAndMoveToNextQuestion={props.updateTextAnswerAndMoveToNextQuestion} setYear={setYear} setMonth={setMonth} />
                    </div>
                    : null
                  }
                </div>

              case "select":

                const dropDownOptions = props.question?.answers?.map((ans, index) => {
                  return ({ ...ans, value: ans.desktopText, label: ans.desktopText })
                })

                return (
                  <div className="select-container">
                    <Select id="select" className={`select ${progress === 0 ? '' : 'border-selected'}`} label="Select" value={progress} IconComponent={(props) => (<ExpandMore {...props} />)}
                    MenuProps={{ classes: { paper: classes.menulist } }}
                    onChange={(e) => updateDropDownStep(e.target.value, props.question.orderNo)} >
                      <MenuItem value={0}>Select</MenuItem>
                      {props.question?.answers?.map((data, index) => {
                        return <MenuItem key={index} value={data.value}>{data.desktopText}</MenuItem>
                      })}
                    </Select>

                    {/* <ReactSelect classNamePrefix="react-select" className="form-details-text react-select"
                      options={dropDownOptions} // options={props.question?.answers}
                      onChange={(e) => updateDropDownStep(e.orderNo, props.question.orderNo)}
                      value={props.question.answer}
                      theme={(theme) => ({
                      ...theme,
                      colors: { ...theme.colors,
                        primary25: '#F3F3F3', // Selected option background color
                        primary50: '#fff', // on click option color
                        primary: '#3B3A3A',
                        neutral0: '#F4F4F4',
                        neutral50: '#A5A5A5', // Placeholder text color
                      }, cursor: "pointer",
                    })} placeholder={"Select"} /> */}
                  </div>
                );

              default: 
                return <div className="slider-container" style={{ width: sliderWidth + '%' }}>
                  <div className="slider">
                    { showSlider ? <Slider key={props.question?.id}
                      valueLabelDisplay="off"
                      step={1} min={1} max={maxWidth}
                      value={progress}
                      // value={updateSliderTextHighlight(progress)}
                      className="custom-slider"
                    /> : <div className="slider-none-adjust"></div> }
                    <div className="slider-points">
                    { 
                      props.question?.answers?.map((data, index) => (
                        <a className="point" key={`points-${index}`} style={{ left: getLeft(index) + '%', opacity: checkOpacity(index) }} onClick={() => updateStep(data.value, data.orderNo)}></a>
                      ))
                    }
                    </div>
                    <LinearProgress className="custom-bar" variant="determinate" value={0} />
                    
                  </div>
                  <div className="slider-labels">
                    { 
                      props.question?.answers?.map((data, index) => {
                        return (
                        // were using checkSelected(data.value) here before this change to orderNo
                        <div className={checkSelected(data.orderNo)} key={`labels-${index}`} style={{ left: getLeft(index) + '%' }} onClick={() => updateStep(data.value, data.orderNo)}>
                          {/* <p className="desktop" style={getLabelStyle()}>{data.desktopText}</p> */}
                          <p className="desktop" style={getLabelStyle()} dangerouslySetInnerHTML={{ __html: data.desktopText }} />
                          {/* <p className="mobile" style={getLabelStyle()}>{data.mobileText}</p> */}
                          <p className="mobile" style={getLabelStyle()} dangerouslySetInnerHTML={{ __html: data.mobileText }} />
                        </div>
                      );
                    })}
                  </div>
                </div>
            }
          }).call(this)
        }
  
        {/* <div className="slider-container" style={{ width: state.sliderWidth + '%' }}>
          <div className="slider">
            { state.showSlider ? <Slider
              key={props.question.id}
              valueLabelDisplay="off"
              step={1}
              min={1}
              max={state.maxWidth}
              value={state.progress}
              className="custom-slider"
            /> : <div className="slider-none-adjust"></div> }
            <div className="slider-points">
            {/* { !state.showSlider ? 
              <a className="point" style={{ left: '0%'}} onClick={() => updateStep(1)}></a> : null } */}
              {/* { 
                props.question?.answers.map((data, index) => (
                  <a className="point" key={`points-${index}`} style={{ left: getLeft(index) + '%', opacity: checkOpacity(index) }} onClick={() => updateStep(data.value)}></a>
                ))
              }
            </div>
            <LinearProgress className="custom-bar" variant="determinate" value={0} />
            
          </div>
          <div className="slider-labels">
            { 
              props.question?.answers.map((data, index) => (
                <div className={checkSelected(data, data.value)} key={`labels-${index}`} style={{ left: getLeft(index) + '%'}} onClick={() => updateStep(data.value)}>
                  <p className="desktop" style={getLabelStyle()} dangerouslySetInnerHTML={{__html: data.desktopText }}></p>
                  <p className="mobile" style={getLabelStyle()} dangerouslySetInnerHTML={{__html: data.mobileText }}></p>
                </div>
              ))
            }
          </div>
        </div> */}
  
      </div>
    );
  }
  
export default Question;



const SkipQuestionCheckbox = (props) => {

  const [notApplicable, setNotApplicable] = useState(false);

  useEffect(() => {
    if(props.question?.answer == 0) {
      setNotApplicable(true);
    }
  }, [props.question?.answer]);

  const notApplicableChecked = () => {
    const answerType = props.question.answerType;
    if (props.question.answerType == "slider") {
      // props.skipQuestionFunction(0);
    }

    if (answerType == "month_year") {
      // props.updateMonthYear("00", "0000");
      props.updateTextAnswerAndMoveToNextQuestion(props.question?.id, "0000-00", 0, true);
    }

    if (answerType == "multiple_choice") {
      props.skipQuestionFunction(0);
      props.setSelectedAnswers("");
    }
  }

  const notApplicableUnchecked = () => {
    const answerType = props.question.answerType;
    if (answerType == "slider") {
      // props.skipQuestionFunction(0);
    }

    if (answerType == "month_year") {
      // props.updateMonthYear({ name: "January", number: "01" }, 1995);
      props.updateTextAnswerAndMoveToNextQuestion(props.question?.id, undefined, undefined, false);
      props.setYear("");
      props.setMonth("");
    }

    if (answerType == "multiple_choice") {
      props.skipQuestionFunction(undefined);
      props.setSelectedAnswers("");
    }
  }

  return (
    <FormControlLabel control={
      <Checkbox checked={notApplicable} 
        onChange={(e) => {
          setNotApplicable(e.target.checked);
          if (e.target.checked) {
            notApplicableChecked();
          } else {
            notApplicableUnchecked();
          }
        }} 
        sx={{ color: "#343333",
          '&.Mui-checked': { color: "#EF8933", },
        }}
      />
    } label="Not applicable" style={{ cursor: "pointer" }}
      className='not-applicable'
    />
  );
  
}

