 import { Button } from "@mui/material";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import prevArrow from '../../Assets/Images/prev-arrow.svg';
import useWindowDimensions from "../../Hooks/useWindowDimensions";

const Intro = () => {

	const { windowWidth } = useWindowDimensions();
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const { id, pageId } = useParams();

	const formLink = () => {
    navigate("/first-form/" + id + "?" + searchParams, { replace: true });
  }
	
	const assessmentLink = () => {
		if(pageId == 729) {
			navigate("/assessment/" + id + "/728?" + searchParams, { replace: true });
		} else if(pageId == 734) {
			navigate("/assessment/" + id + "/735?" + searchParams, { replace: true });
		} else if(pageId == 736) {
			navigate("/assessment/" + id + "/737?" + searchParams, { replace: true });
		} else if(pageId == 738) {
			navigate("/assessment/" + id + "/739?" + searchParams, { replace: true });
		}
  }

	const prevPage = () => {
		if(pageId == 729) {
			navigate("/?" + searchParams, { replace: true });
		} else if(pageId == 734) {
			navigate("/assessment/" + id + "/728?" + searchParams, { replace: true });
		} else if(pageId == 736) {
			navigate("/assessment/" + id + "/735?" + searchParams, { replace: true });
		} else if(pageId == 738) {
			navigate("/assessment/" + id + "/737?" + searchParams, { replace: true });
		}
	}

	if(pageId == 729) {
		return (
			// <div className="intro-container container">
			// 	<div className="intro">
			// 		<div className="intro-heading">
			// 			<h1>When you see <span>engagement opportunities</span> in the upcoming questions, consider how</h1>
			// 		</div>
			// 		<div className="intro-para">
			// 			<p className="para-one">Your church engages with your community through small groups, outreach efforts, meals, sports, personal care, food drives, special events and more.</p>
			// 			<p className="para-two">All of it counts.</p>
			// 		</div>
			// 		<div className="cta">
			// 			<Button className="cta-btn" onClick={() => formLink()}>GOT IT!</Button>
			// 		</div>
			// 	</div>
			// </div>
			<div className='intro'>
				<div className='sub-container'>
					<div className="assessment-intro">
						<div style={{display:"block"}}> 
							<span className="intro-text">We have segmented this reflection into four sections based on the themes in <i>Lead with Prayer</i>. Each section consists of seven statements. Take a moment to prayerfully consider your responses before submitting them, and ask the Spirit to help you evaluate yourself honestly.</span>
						</div>
					</div>
					<div className="middle-content">
						<span className="first">Part A</span>
						<span className="second">Prioritizing Prayer</span>
	
					</div>
					<div className="bottom-content">
						<span className="heading"> We discovered some wonderful consistent traits among world-changing Christian leaders through the research and interview process for <i>Lead with Prayer</i>. The most significant of these traits was that leaders prioritize prayer.</span>
						{/* <span className="points">(1 = Strongly Disagree; 5 = Strongly Agree)</span> */}
					</div>
					<div className="step-buttons">
						<div className="prev-btn">
							<Button className="cta-btn" onClick={() => prevPage()}>
								{windowWidth <= 650 ? 
									<img src={prevArrow} alt="previous button" /> :
									<><img src={prevArrow} alt="previous button" />&nbsp;&nbsp;PREV</>
								}
							</Button>
						</div>
						<div className="cta">
							<Button className="cta-btn" onClick={() => assessmentLink()}>CONTINUE</Button>
						</div>
						<div className="step-btn"></div>
					</div>
				</div>
			</div>
	
		)
	} else if(pageId == 734) {
		return (
			<div className='intro'>
				<div className='sub-container'>
					<div className="middle-content">
						<span className="first">Part B</span>
						<span className="second">Practicing Prayer</span>
	
					</div>
					<div className="bottom-content">
						<span className="heading">We found that world-changing leaders develop a set of consistent practices to ensure that prayer is at the center of their lives. Please rate your level of agreement with the following statements.</span>
						{/* <span className="points">(1 = Strongly Disagree; 5 = Strongly Agree)</span> */}
					</div>
					<div className="step-buttons">
						<div className="prev-btn">
							<Button className="cta-btn" onClick={() => prevPage()}>
								{windowWidth <= 650 ? 
									<img src={prevArrow} alt="previous button" /> :
									<><img src={prevArrow} alt="previous button" />&nbsp;&nbsp;PREV</>
								}
							</Button>
						</div>
						<div className="cta">
							<Button className="cta-btn" onClick={() => assessmentLink()}>CONTINUE</Button>
						</div>
						<div className="step-btn"></div>
					</div>
				</div>
			</div>
	
		)
	} else if(pageId == 736) {
		return (
			<div className='intro'>
				<div className='sub-container'>
					<div className="middle-content">
						<span className="first">Part C</span>
						<span className="second">Modeling Prayer</span>
	
					</div>
					<div className="bottom-content">
						<span className="heading">The world-changing leaders we interviewed devoted significant time to praying for the ministry decisions they make and the people they lead. Please rate your level of agreement with the following statements.</span>
						{/* <span className="points">(1 = Strongly Disagree; 5 = Strongly Agree)</span> */}
					</div>
					<div className="step-buttons">
						<div className="prev-btn">
							<Button className="cta-btn" onClick={() => prevPage()}>
								{windowWidth <= 650 ? 
									<img src={prevArrow} alt="previous button" /> :
									<><img src={prevArrow} alt="previous button" />&nbsp;&nbsp;PREV</>
								}
							</Button>
						</div>
						<div className="cta">
							<Button className="cta-btn" onClick={() => assessmentLink()}>CONTINUE</Button>
						</div>
						<div className="step-btn"></div>
					</div>
				</div>
			</div>
	
		)
	} else if(pageId == 738) {
		return (
			<div className='intro'>
				<div className='sub-container'>
					<div className="middle-content">
						<span className="first">Part D</span>
						<span className="second">Promoting Prayer</span>
	
					</div>
					<div className="bottom-content">
						<span className="heading">As people of prayer, world-changing leaders set up organizational rhythms that emphasize and reinforce the centrality of prayer. Please rate your level of agreement with the following statements.</span>
						{/* <span className="points">(1 = Strongly Disagree; 5 = Strongly Agree)</span> */}
					</div>
					<div className="step-buttons">
						<div className="prev-btn">
							<Button className="cta-btn" onClick={() => prevPage()}>
								{windowWidth <= 650 ? 
									<img src={prevArrow} alt="previous button" /> :
									<><img src={prevArrow} alt="previous button" />&nbsp;&nbsp;PREV</>
								}
							</Button>
						</div>
						<div className="cta">
							<Button className="cta-btn" onClick={() => assessmentLink()}>CONTINUE</Button>
						</div>
						<div className="step-btn"></div>
					</div>
				</div>
			</div>
	
		)
	}
}

export default Intro;
