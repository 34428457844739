import { motion } from 'framer-motion'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

export const InitialTransition = () => {

  const greenBox = {
    initial: {
      position: "absolute",
      zIndex: "2",
      height: "100vh",
      top: 0,
    },
    animate: {
      height: 0,
      transition: {
        duration: 1.5,
        ease: [0.87, 0, 0.13, 1],
      },
    },
  };

  const LinearBox = {
    initial: {
      opacity: 1,
    },
    animate: {
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: [0.87, 0, 0.13, 1],
      },
    },
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#293745",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#b95f19",
    },
  }));

  return (
    <div className="inset-0 flex items-center justify-center">
      <motion.div
        className="relative z-50 w-full loading"
        initial="initial"
        animate="animate"
        variants={greenBox}
      > 
        <motion.div 
        initial="initial"
        animate="animate"
        variants={LinearBox} 
        style={{width: "400px"}}>
          <BorderLinearProgress value={50} />
        </motion.div>
      </motion.div>      
    </div>
  );
};