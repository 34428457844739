import { useState, useRef, useEffect, useMemo } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";

// import VideoThumbnail from '../../Assets/Images/video-thumbnail.jpg';
import VideoThumbnail from '../../Assets/Images/video-thumbnail.jpeg';
// import VideoThumbnailMobile from '../Assets/Images/video-thumbnail-mobile.png';
// import PlayButton from '../Assets/Images/play-button.png';
// import playBtn from '../../Assets/Images/play-btn.png';
// import playBtnHover from '../../Assets/Images/play-btn-hover.png';
import playBtn from '../../Assets/Images/play-btn-triangle.png';
import playBtnHover from '../../Assets/Images/play-btn-triangle.png';
import Clock from '../../Assets/Images/clock.png';
import { Close, Pause, PlayArrow } from "@mui/icons-material";
// import { Button } from '@mui/material';
import Button from '../../Components/Button';
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import '../../App.scss';


const Start = props => {

  const { windowWidth } = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [isVidPaused, setIsVidPaused] = useState(false);
  const [btnHovered, setBtnHovered] = useState(false);
  // const [startPlaying, setStartPlaying]   = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);
  const [playBtnImg, setPlayBtnImg] = useState(playBtn);

  const vidRef = useRef(null);
  const introElmRef = useRef(null);
  const videoElmRef = useRef(null);

  const display = searchParams.get("display");
  const navigate = useNavigate();



  // useEffect(() => {
  //   if(display === "preview") {
  //     console.log("changed background img for embedded preview display...");
  //     document.body.style.background = `#242424 url(${introBgImg}) repeat`;
  //   } else {
  //     document.body.style.background = `#414042`;
  //   }
  // }, [display]);

  useEffect(() => {
    const introElm = introElmRef.current;
    const videoElm = videoElmRef.current;
    if (windowWidth <= 830) {
      introElm.style.width = "100%";
      videoElm.style.width = "100%";
      introElm.style.maxHeight = "unset";
    } else if (windowWidth > 830) {
      if (isPlayingVideo) {
        introElm.style.width = "0%";
        videoElm.style.width = "100%";
      } else {
        introElm.style.width = "50%";
        videoElm.style.width = "50%";
        videoElm.style.maxHeight = "550px";
        introElm.style.maxHeight = "550px";
      }
    }
  }, [isPlayingVideo, windowWidth]);

  const createAssessment = () => {

    // TODO: change the pageId according to what the page ID is for this assessment
    const pageId = 790;

    let leaderId = 0;
    let userId = 0;
    let isMember = false;
    if (searchParams?.get("leader")) {
      leaderId = searchParams?.get("leader");
      userId = searchParams?.get("user");
      isMember = true;
    }

    const clientId = process.env.REACT_APP_CLIENT_ID;
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ clientId, isMember, leaderId, userId })
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        // navigate("/intro/" + data.id + "?" + searchParams, { replace: true });
        // navigate("/before-you-start/" + data.id + "?" + searchParams, { replace: true });
        navigate("/assessment/" + data.id + "/" + pageId + "?" + searchParams, { replace: true });
        console.log("data", data);
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => console.error("Failed to create new assessment...", err));
  }


  const howItWorksLink = () => {
    if (searchParams == "") {
      return "/how-it-works";
    } else {
      return "/how-it-works?" + searchParams;
    }
  }
  // console.log(searchParams.get("display"));

  const assessmentLink = () => {
    return "/assessment?" + searchParams;
  }

  const preAssessmentQuestionsLink = () => {
    navigate("/pre-additional-questions?" + searchParams);
  }

  const introPageLink = () => {
    createAssessment();
    // navigate("/intro/" + id + "?" + searchParams);
  }

  const playVideo = (e) => {
    
    if (windowWidth > 830) {
    const introElm = introElmRef.current;
    const videoElm = videoElmRef.current;
    const video = vidRef.current;
      introElm.style.cssText = "width: 0%; padding: 0; overflow: hidden; max-height: 520px;"
      videoElm.style.cssText = "width: 100%; border-radius: 10px";
      video.style.cssText = "width: 100%; margin: 0 auto; border-radius: 10px; background-color: #DFF8FF;";
      // if(display === "preview") {
      //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
      // } else videoElm.style.backgroundColor = "#435B63";
    }
    vidRef.current.play();
    setIsPlayingVideo(true);
    setIsVidPaused(false);
    // if(!startPlaying) {
    //   setStartPlaying(true);
    // }
  }

  const pauseVideo = (e) => {
    // vidRef.current.pause();
    // setPlayingVideo(false);
    vidRef.current.pause();
    setIsVidPaused(true);
    setBtnHovered(false);
    setPlayBtnImg(playBtn);

    if(windowWidth > 830) {
      const introElm = introElmRef.current;
      const videoElm = videoElmRef.current;
      const video = vidRef.current;

      introElm.style.cssText = "width: 50%; padding: 0 2% 0 3%;"
      videoElm.style.cssText = "width: 50%; margin: 0 auto;";
      video.style.cssText = "height: 100%; width: auto;";
      // if(display === "preview") {
      //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
      // } else videoElm.style.backgroundColor = "#435B63";
    }
    setIsPlayingVideo(false);
  }

  const handlePlayPause = () => {
    if (isPlayingVideo) {
      pauseVideo();
    } else {
      playVideo();
    }
    setIsPlayingVideo(!isPlayingVideo);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(vidRef.current.currentTime);
  };

  const handleSeek = (e) => {
    const newTime = e.target.value;
    vidRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleVideoPlaybackEnd = () => {
    setBtnHovered(false);
    setIsPlayingVideo(false);
    setIsVidPaused(false);
    setPlayBtnImg(playBtn);
    showPlayButton();
    
    const introElm = introElmRef.current;
    const videoElm = videoElmRef.current;

    if(windowWidth > 830) {

      introElm.style.cssText = "width: 50%; padding: 0px 3%; overflow: hidden; max-height: 520px;";
      videoElm.style.cssText = "width: 50%; margin: 0 auto;";

      // if(display === "preview") {
      //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
      // } else videoElm.style.backgroundColor = "#435B63";
    } else if (windowWidth <= 830) {
      introElm.style.width = "100%";
      videoElm.style.width = "100%";
    }
  }

  const showThumbnail = useMemo(() => {

    if (isVidPaused) {
      return <img alt="video thumbnail" className="thumbnail" src={VideoThumbnail} style={{ opacity: '1' }}
      // onClick={(e) => pauseVideo(e)} 
      />;
    }
    if (!isPlayingVideo && !isVidPaused) {
      return <img alt="video thumbnail" className="thumbnail" src={VideoThumbnail} style={{ opacity: '1' }}
      // onClick={(e) => pauseVideo(e)} 
      />;
    } else {
      // return <img alt="video thumbnail" className="thumbnail" src={VideoThumbnail} style={{ opacity: '0' }} onClick={(e) => pauseVideo(e)} />;
      return null;
      // if(startPlaying) {
      //   return null
      // } else {
      //   return <img alt="video thumbnail" className="thumbnail" src={VideoThumbnail} />
      // }
    }
    // }, [isPlayingVideo, startPlaying]);
  }, [isPlayingVideo, isVidPaused]);

  const showPlayButton = () => {
    if (isPlayingVideo) {
      return null
    } else {
      return <img alt="play button" className="playbtn" src={playBtnImg} 
      onClick={(e) => playVideo(e)} 
      onMouseEnter={() => setPlayBtnImg(playBtnHover)} 
      onMouseLeave={() => setPlayBtnImg(playBtn)} />
    }
  }

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    if (!seconds) return "0:00";
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  const handleSeekStart = () => {
    setIsSeeking(true);
  }

  const handleSeekEnd = () => {
    setIsSeeking(false);
  }


  const vidsrcURL = "https://40parables-assets.s3.amazonaws.com/bleat-XPastor-Intro.mp4";

  return (
    <div className="container start">
      <div className="video" ref={videoElmRef}>
        {/* <video ref={vidRef} controls={false}
          onEnded={handleVideoPlaybackEnd}
          onPlay={() => setIsPlayingVideo(true)}
          onPause={() => {
            setIsPlayingVideo(false);
            setIsVidPaused(true);
            showPlayButton();
          }}
          onTimeUpdate={handleTimeUpdate}
        >
          <source src={vidsrcURL} type="video/mp4" />
        </video> */}
        {/* custom video controls */}
        {isPlayingVideo && <div className="custom-controls">
          <button onClick={handlePlayPause}>
            {isPlayingVideo ? <Pause /> : <PlayArrow />}
          </button>
          <input className="custom-slider"
            type="range" min="0" max={vidRef.current ? vidRef.current.duration : 0}
            value={currentTime} onChange={handleSeek}
          />
          <span>
            {formatTime(Math.floor(currentTime))} /{' '}
            {formatTime(Math.floor(vidRef.current ? vidRef.current.duration : 0))}
          </span>
        </div>}
        {/* custom video controls */}
        {/* {showThumbnail()} */}
        {showThumbnail} {/* using useMemo */}
        {/* {showPlayButton()} */}

        {isPlayingVideo && <Close className="close-video" style={{
          position: "absolute", height: "20px", width: "20px", color: "#fefefede", opacity: 0.85, zIndex: 99, padding: "15px", top: "5px", cursor: "pointer",
          right: windowWidth > 1100 ? "15px" : "5px"
        }} onClick={(e) => pauseVideo(e)} />}

      </div>
      {/* {windowWidth <= 830 && <div style={{ backgroundColor: "#FFFFFF", height: "15px" }}></div>} */}
      <div className="intro" ref={introElmRef}>
        <h1>Map the next steps in your spiritual adventure.</h1>
        {/* <p className='intro-para'>Money often sets churches off-track in 3 aspects: people, planning, and protection.</p> */}
        {/* <div className="completion">See how you compare* with similarly-sized American churches</div> */}
        <ul type="1" className="intro-points-list">
          {searchParams.get("leader") ? <>
            {/* <li>Take this quick 2-minute assessment</li>
            <li>Unlock the next steps to grow in your faith</li> */}
          </> : <>
            {/* <li>Take this quick 2-minute assessment</li>
            <li>Unlock the next steps to grow in your faith</li> */}
              <p>This 21-question assessment will help us know you better and lead you to the most suitable opportunities to grow your relationship with God.</p>
          </>}
        </ul>
        {/* <h2>Measure your individual strengths and challenges in these 5 people patterns that determine your unique leadership style.</h2> */}

        <div className="completion" style={searchParams.get("leader") && windowWidth > 830 ? { marginTop: '47px' } : null}>
          {/* <div className='btn-container'> */}
          {/* <Link to={assessmentLink()} className="cta">Get Started</Link> */}
          {/* <Button className="cta" onClick={() => introPageLink()}>FIND OUT</Button> */}
          <Button variant="custom" className="cta" onClick={() => introPageLink()}>Get Started</Button>
          {/* </div> */}
          <div className='time-estimate'>
            <div className='img-container'><img className='clock-icon' src={Clock} alt="estimated time" /></div><span>{searchParams.get("leader") ? "3 mins" : "3 mins"}</span>
          </div>
        </div>


        <div className='para-container'>
          <p className='para'>We promise not to sell your information to a timeshare company or reach out about your car’s extended warranty. Your results will remain completely anonymous.</p>
        </div>
      </div>

    </div>
  );
}

export default Start;
