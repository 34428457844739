import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import { Button } from '@mui/material';

import alphaimage from '../../Assets/Images/alphaimage.svg';
import opImage1 from '../../Assets/Images/options_img_1.png'
import opImage2 from '../../Assets/Images/options_img_2.png'
import opImage3 from '../../Assets/Images/options_img_3.png'
import opImage4 from '../../Assets/Images/options_img_4.png'
import opImage5 from '../../Assets/Images/options_img_5.png'
import communityImage from '../../Assets/Images/community_img.png'
import volunteerImage from '../../Assets/Images/volunteer_img.png'
import givingImage from '../../Assets/Images/giving_img.png'
import baptismImage from '../../Assets/Images/baptism_img.png'
import dailytimewithGodImage from '../../Assets/Images/daily_time_img.png'
import weeklyBibleTeachingImage from '../../Assets/Images/bible_teaching_img.png'
import sharingyourStoryImage from '../../Assets/Images/sharing_story_img.png'
import useWindowDimensions from '../../Hooks/useWindowDimensions';

const Outro = () => {

  const { id, pageId } = useParams();
  const { windowWidth } = useWindowDimensions();

  const [ pageType, setPageType ] = useState('pre-seeker');
  const [isLoading, setIsLoading] = useState(false);
  const [apiData, setApiData] = useState();
  const [variableScores, setVariableScores] = useState();
  const [displayRecommendedSection, setDisplayRecommendedSection] = useState(false);

  useEffect(() => {
    document.body.style.backgroundColor ="#F1F1F1"

  }, [windowWidth]);

  useEffect(() => {
    getAdditional();
  }, []);

  function getAdditional() {

    let clientId = process.env.REACT_APP_CLIENT_ID;
    const additionalQPageId = 791; // this also has variables scores

    // fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + clientId + '/additional', {
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/' + additionalQPageId, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        console.log("data", data);
        setApiData(data);
        setVariableScores(data?.data?.variableScores);
        const variableScores = data?.data?.variableScores;
        const filteredVars = variableScores?.filter(obj => obj.variable === "nature" || obj.variable === "adventure");
        const shouldDisplayRecommendedSection = filteredVars.some(obj => obj.score > 3);
        console.log("shouldDisplayRecommendedSection", shouldDisplayRecommendedSection);
        setDisplayRecommendedSection(shouldDisplayRecommendedSection);
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
      }
    }).catch((err) => {
      console.error("Failed to fetch variable scores...", err);
      setIsLoading(false);
    });

  }

  useEffect(() => {
    if(pageId == 0) {
      setPageType('pre-seeker');
    } else if(pageId == 1) {
      setPageType('community');
    } else if(pageId == 2) {
      setPageType('volunteer');
    } else if(pageId == 3) {
      setPageType('giving');
    } else if(pageId == 4) {
      setPageType('baptism');
    } else if(pageId == 5) {
      setPageType('daily_time');
    } else if(pageId == 6) {
      setPageType('bible_teaching');
    } else if(pageId == 7) {
      setPageType('sharing_story');
    }
  }, [pageId, pageType]);


  switch (pageType) {
    case "pre-seeker":
      return <PreSeeker windowWidth={windowWidth} displayRecommendedSection={displayRecommendedSection} />
    case "community":
      return <Community windowWidth={windowWidth} displayRecommendedSection={displayRecommendedSection} />
    case "volunteer":
      return <Volunteer windowWidth={windowWidth} displayRecommendedSection={displayRecommendedSection} />
    case "giving":
      return <Giving windowWidth={windowWidth} displayRecommendedSection={displayRecommendedSection} />
    case "baptism":
      return <Baptism windowWidth={windowWidth} displayRecommendedSection={displayRecommendedSection} />
    case "daily_time":
      return <DailyTimeWithGod windowWidth={windowWidth} displayRecommendedSection={displayRecommendedSection} />
    case "bible_teaching":
      return <WeeklyBibleTeaching windowWidth={windowWidth} displayRecommendedSection={displayRecommendedSection} />
    case "sharing_story":
      return <SharingYourStory windowWidth={windowWidth} displayRecommendedSection={displayRecommendedSection} />
    default:
      break;
  }
}


const PreSeeker = ({ windowWidth }) => {
  return (
    <div className="outro">
        <h1 className='heading'>Thank you for taking the time to answer.</h1>
        <p className='sub-heading'>Although you may not be curious about the God of the Bible right now, you’re always welcome at Crossroads!
        However, we believe the 7 Proven Practices may not be the best next steps for you as they're meant to help learn more about God and His character. Nonetheless, we want to encourage you in your pursuit of truth. Look at the articles below to see if they might interest you.</p>

      {/* {windowWidth > 927 &&
        <div className='wide-card'>
          <img src={alphaimage} alt="alpha" />
          <div className='alpha-items'>
            <h1>Alpha</h1>
            <p>Alpha is an 11-week course that creates a space, online or in person, where people are excited to bring their friends for a conversation about faith, life and God.</p>
            <Button className='join-btn'>JOIN ALPHA</Button>
          </div>
        </div>} */}

        <SmallCard />

    </div>
  )
}


const Community = (props) => {
  return (
      <div className='outro'>
        <h1 className='heading'>Thanks for taking the time to complete this assessment.</h1>

        <p className='sub-heading'>
          Everyone starts their journey somewhere. With God’s help, we can all take the next step in our relationship with Him. We want to be of service on your journey.
          <br/>Try our suggestions below to discover what might be the right next step for you.
        </p>

        <div className='wide-card'>
          <div className='img-container'>
            <img src={communityImage} alt="alpha" />
          </div>
          <div className='alpha-items'>
            <h1>Join Community</h1>
            <p>If you want to go fast, go alone. If you want to go far, go together. You need other people who know you and push you to be the very best version of yourself. Growth happens in community.</p>
            <Button className='join-btn'><a href="https://www.crossroads.net/7pp-tips?key=joinCommunity" title="Join Community" target="_blank" style={{color: "white", textDecoration:"none"}} rel="noreferrer">PLUG IN</a></Button>
          </div>
        </div>

        {props.displayRecommendedSection && <>
          <div className='divider'>
            <div className='vertical-divider'></div>
            <p>We highly recommend these</p>
            <div className='vertical-divider'></div>
          </div>
          
          <MidCard />
        </>}

      </div>
    )
}


const Volunteer = (props) => {
  return (
      <div className='outro'>
        <h1 className='heading'>Thanks for taking the time to complete this assessment.</h1>

        <p className='sub-heading'>
          Everyone starts their journey somewhere. With God’s help, we can all take the next step in our relationship with Him. We want to be of service on your journey.
          <br/>Try our suggestions below to discover what might be the right next step for you.
        </p>

        <div className='wide-card'>
          <div className='img-container'>
            <img src={volunteerImage} alt="alpha" />
          </div>
          <div className='alpha-items'>
            <h1>Serve others</h1>
            <p>When you help others, you learn things about yourself. In other words, you grow. Growth happens when you get off the sidelines.</p>
            <Button className='join-btn'><a href="https://www.crossroads.net/7pp-tips?key=serveOthers" title="Serve Others" target="_blank" style={{color: "white", textDecoration:"none"}} rel="noreferrer">GET GROWING</a></Button>
          </div>
        </div>

        {props.displayRecommendedSection && <>
          <div className='divider'>
            <div className='vertical-divider'></div>
            <p>We highly recommend these</p>
            <div className='vertical-divider'></div>
          </div>

          <MidCard />
        </>}

      </div>
  )
}


const Giving = (props) => {
  return (
      <div className='outro'>
        <h1 className='heading'>Thanks for taking the time to complete this assessment.</h1>
        
        <p className='sub-heading'>
          Everyone starts their journey somewhere. With God’s help, we can all take the next step in our relationship with Him. We want to be of service on your journey.
          <br/>Try our suggestions below to discover what might be the right next step for you.
        </p>

        <div className='wide-card'>
          <div className='img-container'>
            <img src={givingImage} alt="alpha" />
          </div>
          <div className='alpha-items'>
            <h1>Live Generously</h1>
            <p>Generosity stretches us. When we’re generous, we’re telling God that we trust him. As that trust grows, so does our relationship with God.</p>
            <Button className='join-btn'><a href="https://www.crossroads.net/7pp-tips?key=liveGenerously" title="Live Generously" target="_blank" style={{color: "white", textDecoration:"none"}} rel="noreferrer">GROW YOUR GIVING</a></Button>
          </div>
        </div>

        {props.displayRecommendedSection && <>
          <div className='divider'>
            <div className='vertical-divider'></div>
            <p>We highly recommend these</p>
            <div className='vertical-divider'></div>
          </div>
          
          <MidCard />
        </>}

      </div>
    )
}


const Baptism = (props) => {
  return (
      <div className='outro'>
        <h1 className='heading'>Thanks for taking the time to complete this assessment.</h1>
        
        <p className='sub-heading'>
          Everyone starts their journey somewhere. With God’s help, we can all take the next step in our relationship with Him. We want to be of service on your journey.
          <br/>Try our suggestions below to discover what might be the right next step for you.
        </p>

        <div className='wide-card'>
          <div className='img-container'>
            <img src={baptismImage} alt="alpha" />
          </div>
          <div className='alpha-items'>
            <h1>Get Baptized</h1>
            <p>It’s a vow of loyalty to God. When you’re ready to surrender, you mark the moment.</p>
            <Button className='join-btn'><a href="https://www.crossroads.net/7pp-tips?key=getBaptized" title="Get Baptized" target="_blank" style={{color: "white", textDecoration:"none"}} rel="noreferrer">LEARN MORE</a></Button>
          </div>
        </div>

        {props.displayRecommendedSection && <>
          <div className='divider'>
            <div className='vertical-divider'></div>
            <p>We highly recommend these</p>
            <div className='vertical-divider'></div>
          </div>
        
          <MidCard />        
        </>}

      </div>
    )
} 


const DailyTimeWithGod = (props) => {
  return (
      <div className='outro'>
        <h1 className='heading'>Thanks for taking the time to complete this assessment.</h1>
       
        <p className='sub-heading'>
          Everyone starts their journey somewhere. With God’s help, we can all take the next step in our relationship with Him. We want to be of service on your journey.
          <br/>Try our suggestions below to discover what might be the right next step for you.
        </p>

        <div className='wide-card'>
          <div className='img-container'>
            <img src={dailytimewithGodImage} alt="alpha" />
          </div>
          <div className='alpha-items'>
            <h1>Connect With God Daily</h1>
            <p>Relationships require ongoing communication. When it comes to God, that often looks like praying and reading the Bible. It’s a practice that’s foundational for spiritual growth.</p>
            <Button className='join-btn'><a href="https://www.crossroads.net/7pp-tips?key=connectWithGodDaily" title="Connect With God Daily" target="_blank" style={{color: "white", textDecoration:"none"}} rel="noreferrer">DIVE DEEPER</a></Button>
          </div>
        </div>

        {props.displayRecommendedSection && <>
          <div className='divider'>
            <div className='vertical-divider'></div>
            <p>We highly recommend these</p>
            <div className='vertical-divider'></div>
          </div>
          
          <MidCard />
        </>}

      </div>
    )
}


const WeeklyBibleTeaching = (props) => {
  return (
      <div className='outro'>
        <h1 className='heading'>Thanks for taking the time to complete this assessment.</h1>
          
        <p className='sub-heading'>
          Everyone starts their journey somewhere. With God’s help, we can all take the next step in our relationship with Him. We want to be of service on your journey.
          <br/>Try our suggestions below to discover what might be the right next step for you.
        </p>

        <div className='wide-card'>
          <div className='img-container'>
            <img src={weeklyBibleTeachingImage} alt="alpha" />
          </div>
          <div className='alpha-items'>
            <h1>Receive Teaching Weekly</h1>
            <p>For 2,000 years, every follower or disciple of Jesus has been a student of his teaching. Deciding to be a student challenges you to grow.</p>
            <Button className='join-btn'><a href="https://www.crossroads.net/7pp-tips?key=receiveTeachingWeekly" title="Receive Teaching Weekly" target="_blank" style={{color: "white", textDecoration:"none"}} rel="noreferrer">TEST AND SEE</a></Button>
          </div>
        </div>

        {props.displayRecommendedSection && <>
          <div className='divider'>
            <div className='vertical-divider'></div>
            <p>We highly recommend these</p>
            <div className='vertical-divider'></div>
          </div>
          
          <MidCard />
        </>}

      </div>
    )
}


const SharingYourStory = (props) => {
  return (
      <div className='outro'>
        <h1 className='heading'>Thanks for taking the time to complete this assessment.</h1>
        
        <p className='sub-heading'>
          Everyone starts their journey somewhere. With God’s help, we can all take the next step in our relationship with Him. We want to be of service on your journey.
          <br/>Try our suggestions below to discover what might be the right next step for you.
        </p>

        <div className='wide-card'>
          <div className='img-container'>
            <img src={sharingyourStoryImage} alt="alpha" />
          </div>
          <div className='alpha-items'>
            <h1>Share your story</h1>
            <p>Honing and sharing your story is a powerful practice that leads to growth, personally and for others. Your story can change the world.</p>
            <Button className='join-btn'><a href="https://www.crossroads.net/7pp-tips?key=shareYourStory" title="Share Your Story" target="_blank" style={{color: "white", textDecoration:"none"}} rel="noreferrer">BE THE DIFFERENCE</a></Button>
          
          </div>
        </div>

        {props.displayRecommendedSection && <>
          <div className='divider'>
            <div className='vertical-divider'></div>
            <p>We highly recommend these</p>
            <div className='vertical-divider'></div>
          </div>
          
          <MidCard />
        </>}

      </div>
    )
}


const MidCard = ({ windowWidth }) => {
  return (
          <div className='options' style={{gridTemplateColumns: "repeat(2, 1fr)"}}>
            <div className='mid-card'>
              <div className='mid-image'>
                <img src={ opImage4 } alt="" />
              </div>
              <h3>Crossroads Camp</h3>
              <p className='desc'>Find the perfect camp for you to take a break, recharge, and come home more alive. At Crossroads, we have programs specifically designed to take you to a new place relationally and spiritually.</p>
              <div className='button'><a href="https://www.crossroads.net/camps" target="_blank" rel="noreferrer">Sign Up</a></div>
            </div>
            <div className='mid-card'>
              <div className='mid-image'>
                <img src={ opImage5 } alt="" />
              </div>
              <h3>Take a trip</h3>
              <p className='desc'>When it comes to solving the big problems of injustice, poverty, and exploitation, people often think that “someone else” will step in. At Crossroads, it could be you taking a trip with fellow volunteers and changing the world.</p>
              <div className='button'><a href="https://www.crossroads.net/go/trips" target="_blank" rel="noreferrer">Sign Up</a></div>
            </div>
        </div>
  )
}


const SmallCard = ({ windowWidth }) => {
      const optionsList = [
      { 'img': opImage1, 'name': 'Is God real?', 'desc': 'There are three things you don’t mention in polite dinner conversation: God, politics, and aliens… so let’s talk about all three.', 'btnTxt': 'Read Article', 'href': 'https://www.crossroads.net/media/articles/is-god-real-4-filters-to-discern-a-better-answer' },
      { 'img': opImage2, 'name': 'Does God care about me?', 'desc': 'Does the God who created all the wonderful things of the world really give a second thought to us?Find out.', 'btnTxt': 'Read Article', 'href': 'https://www.crossroads.net/media/articles/does-god-care-about-me-finding-a-risky-answer' },
      { 'img': opImage3, 'name': 'Is God good?', 'desc': 'After the question of God’s existence, this is the one that immediately follows. Existence certainly doesn’t equate to goodness. So, is God good?', 'btnTxt': 'Read Article', 'href': "https://www.crossroads.net/media/articles/is-god-good-how-i-found-an-answer" },
  ]
  return (
      <div className='options'>
        {
          optionsList.map((item, index) => (
            <div className='card' key={index}>
              <div className='sm-img-container'>
                <img src={ item.img } alt="" />
              </div>
              <h3>{ item.name}</h3>
              <p>{ item.desc}</p>
              <div className='button'><a href={item?.href} target='_blank' rel="noreferrer">{item.btnTxt}</a></div>
           </div>
          ))
        }

      </div>
    )
}

export default Outro;
