import { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { Box, Stepper, CircularProgress, LinearProgress } from '@mui/material';
import Button from '../../Components/Button';

import Question from '../../Components/Questions';
import ProgressBar from '../../Components/ProgressBar';
import ProgressBarSingle from '../../Components/ProgressBarSingle';

import prevArrow from '../../Assets/Images/prev-arrow.svg';
import nextArrow from '../../Assets/Images/next-arrow.svg';
import nextArrowWhite from '../../Assets/Images/next-arrow-white.svg';
import prevIcon from '../../Assets/Images/prevIcon.svg';

import useWindowDimensions from '../../Hooks/useWindowDimensions';

// import AssessmentProgressContext from '../Store/AssessmentProgressContext';
import AssessmentQuestionContext from '../../Store/AssessmentQuestionContext';
import QuestionsProgressContext from '../../Store/QuestionsProgressContext';


const AssessmentV3 = () => {

  // const assessProgressCtx = useContext(AssessmentProgressContext);
  // console.log("assessProgressCtx", assessProgressCtx);
  const assessCtx       = useContext(AssessmentQuestionContext);
  const questionsCtx    = useContext(QuestionsProgressContext);
  const { windowWidth } = useWindowDimensions();
  let navigate          = useNavigate();
  const { id, pageId }  = useParams();
  
  console.log("pageId", pageId);
  
  const [searchParams, setSearchParams]     = useSearchParams();
  const [isLoading, setIsLoading]           = useState(true);
  const [activeStep, setActiveStep]         = useState(0);
  const [prevValue, setPrevValue]           = useState(0);
  // const [nextValue, setNextValue]           = useState(0);
  const [sections, setSections]             = useState([]);
  const [questions, setQuestions]           = useState([]);
  const [questionCount, setQuestionCount]   = useState(0);
  const [currentHash, setCurrentHash]       = useState(0);
  const [hasAdditional, setHasAdditional]   = useState(false);
  const [apiData, setApiData]               = useState();
  const [goBackToPrevQ, setGoBackToPrevQ]   = useState(false);
  const [showNextBtn, setShowNextBtn]       = useState(false);
  const [prevBtnClicked, setPrevBtnClicked] = useState(false);
  // const [oldQuestions, setOldQuestions]     = useState();
  
  // const [nextDisabled, setNextDisabled] = useState(true);
  // console.log("searchParams.get('member')", searchParams.get("member"));

  useEffect(() => {
    // to hide Next button when user has just answered the question (500ms delay) and 
    // to show it again when user clicks on Prev button

    let timeoutId = null;
    let conditionToShowNextBtn = (
      (questions?.[activeStep]?.answerType !== "slider" &&
        questions?.[activeStep]?.answerType !== "multiple_choice" &&
        questions?.[activeStep]?.answerType !== "month_year") ||
      (questions?.[activeStep]?.answer >= 0));

    if (conditionToShowNextBtn && !prevBtnClicked) {
      timeoutId = setTimeout(() => {
        setShowNextBtn(true);
      }, 500);
    }

    if (conditionToShowNextBtn && prevBtnClicked) {
      setPrevBtnClicked(false);
      setShowNextBtn(true);
    }

    if (!conditionToShowNextBtn) {
      setShowNextBtn(false);
    }

    return () => {
      clearTimeout(timeoutId);
    }
  }, [prevBtnClicked, activeStep, questions]);

  useEffect(() => {
    // document.body.style.backgroundColor = "#CCCC0021";
    
    setIsLoading(true);
    getQuestions();
    localStorage.removeItem("backFromDetailsPage");
    // if(id != null) {      
    //   getAssessment();
    // } else {
    //   getQuestions();
    // }

    // assessProgressCtx.updateMainSectionsStatus(assessProgressCtx?.mainSections?.map(sec => {
    //   if(sec.title === "Response") return { ...sec, status: "active" };
    //   else return { ...sec, status: "unfinished" };
    // }));

  }, [pageId]);


  // useEffect(() => {
  //   getQuestions();
  // }, [pageId]);
  
  useEffect(() => {
    if(localStorage.getItem("backFromAddQPage") === "true" && questions?.length) {
      // console.log("activeStep", activeStep); console.log("questions", questions);
      setActiveStep(questions?.length - 1);
      localStorage.removeItem("backFromAddQPage");
    }
  }, [questions, pageId]);

  // Run the display logic when the question changes
  useEffect(() => {
    console.log("Do we run??");
    let isDisplay = true;
    // Get all standalone logic (ie parent_id = 0)
    let displayLogic = questions[activeStep]?.logic.filter(obj => obj.parent_id == 0 && obj.logic_type == "display");
    console.log("displayLogic", displayLogic);

    if (displayLogic && displayLogic.length > 0) {
      
      for (let l = 0; l < displayLogic.length; l++) {
        
        let currentLogic = [];
        currentLogic.push(displayLogic[l]);
        let childLogic = questions[activeStep]?.logic.filter(obj => obj.parent_id == displayLogic[l].id);
        currentLogic.push(...childLogic);
        console.log("currentLogic", currentLogic);
        
        if (currentLogic.length > 1) {
          console.log("getOperatorLogic", getOperatorLogic(getQuestionBasedOnVariable(questions, questions[activeStep]?.logic[l]?.variable).answer, questions[activeStep]?.logic[l]?.operator, questions[activeStep]?.logic[l]?.value));
          let subDisplay = 0;
          for (let c = 0; c < currentLogic.length; c++) {
            if (getOperatorLogic(getQuestionBasedOnVariable(questions, currentLogic[c].variable).answer, currentLogic[c].operator, currentLogic[c].value)) {
              subDisplay++;
            }
          }
          if (subDisplay == currentLogic.length) {
            isDisplay = false;
          }
        } else {
          if (getOperatorLogic(getQuestionBasedOnVariable(questions, questions[activeStep]?.logic[l]?.variable).answer, questions[activeStep]?.logic[l]?.operator, questions[activeStep]?.logic[l]?.value)) {
            isDisplay = false;
          }
        }
      }

    }

    if (!isDisplay) {
      assessCtx.updateSkipStatus(questions?.[activeStep]?.id, true);
      setQuestions(questions?.map((q) => {
        if (q.id === questions?.[activeStep]?.id) q.skip = true;
        return q;
      }))
    } else {
      assessCtx.updateSkipStatus(questions?.[activeStep]?.id, false);
      setQuestions(questions?.map((q) => {
        if (q.id === questions?.[activeStep]?.id) q.skip = false;
        return q;
      }))
    }
    
    if (!isDisplay && !goBackToPrevQ) {
      console.log("NEXT QUESTION PLEASE");
      if (assessCtx.questions?.[activeStep]?.skip) {
        setActiveStep(activeStep + 1);
      }
    } else if (!isDisplay && goBackToPrevQ) {
      const idx = activeStep || 0;
      for (let i = idx; i >= 0; i--) {
        if (!assessCtx.questions?.[i]?.skip) {
          setActiveStep(i);
          break;
        }
      }
      setGoBackToPrevQ(false);
    }
  }, [activeStep, assessCtx, goBackToPrevQ]);

  function getQuestions() {

    // if(assessCtx.questions?.length > 0) {
    //   console.log("assessCtx.questions", assessCtx.questions);
    //   setQuestions(assessCtx.questions);
    //   setQuestionCount(assessCtx.questions?.length);
    //   setIsLoading(false);

    //   // go to the last additional question if coming back from Details page 
    //   // typeof localStorage.getItem("backFromDetailsPage") is string so "true"
    //   if(localStorage.getItem("backFromDetailsPage") === "true") {
    //     setActiveStep(assessCtx.questions?.length - 1);
    //   } else {
    //     // go to the first unanswered question 
    //     for (let i = 0; i < assessCtx.questions?.length; i++) {
    //       if(!assessCtx.questions?.[i]?.answer && !assessCtx.questions?.[i]?.answerTxt) {
    //         setActiveStep(i);
    //         break;
    //       } else setActiveStep(assessCtx.questions?.length - 1);
    //     }
    //   }
    // }
    // else {

      let clientId              = process.env.REACT_APP_CLIENT_ID;
      fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/' + pageId, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        return response.json();
      }).then(data => {
        if(data.status == "success") {
          console.log("getQuestions data", data);
          let questions = data?.data.questions?.map((obj) => ({ ...obj, skip: false }));
          if (retrieveStoredAnswers()) {
            let answeredQuestions = [];
            let storedQuestions = retrieveStoredAnswers();
            questions?.forEach(q => {
              let question = storedQuestions.find(obj => obj.id == q.id);
              if(question) {
                q.answerTxt = question.answerTxt;
                q.answer = question.answer;
                q.answers = question.answers;
              }
            })
          }
          // setSections(data.sections);
          // setQuestions(data.questions);
          // setQuestionCount(data.questions?.length);
          // assessCtx.addQuestions(questions);
          // if((data.hasAdditional === "yes") || (data.hasAdditional === true)) setHasAdditional(true);

          // go to first unanswered question 
          let goingForward = localStorage.getItem("goingForward") === "true" ? true : false;

          const jumpToUnansweredQuestion = () => {
            let sortedQuestions = data?.data.questions;
            sortedQuestions = sortedQuestions?.sort((a, b) => a.orderNo - b.orderNo);
            for (let i = 0; i < sortedQuestions?.length; i++) {
              if (sortedQuestions?.[i]?.answer == undefined && assessCtx.questions?.[i]?.answer !== 0) {
                setActiveStep(i);
                break;
              } else setActiveStep(sortedQuestions?.length - 1);
            }
          }

          if (goingForward) {
            setActiveStep(0);
            localStorage.removeItem("goingForward");
          }
          else jumpToUnansweredQuestion();

          setSections(data?.data.sections);
          setQuestionCount(data?.data?.questions?.length);
          const dataqs = data?.data?.questions?.map((obj) => ({ ...obj, skip: false }));
          setQuestions(questions);
          // setQuestions(data?.data?.questions);
          setApiData(data.data);
          assessCtx.addQuestions(questions);
          setIsLoading(false);
        } else {
          // setOpenSnackbar(true);
        }
      }).catch((err) => {
        console.error("Error while fetching questions...", err);
        setIsLoading(false);
      });
    // }
  }

  const currentSectionName = sections?.find(section => section.id === questions?.[activeStep]?.sectionId)?.section;

  console.log("hasAdditional", hasAdditional);


  const retrieveStoredAnswers = () => {
    if (typeof window !== 'undefined') {
      // Get the current localstorage
      let savedAnswers = JSON.parse(localStorage.getItem('assessmentAnswers'));
      if(savedAnswers && savedAnswers.length > 0) {
        return savedAnswers;
      }
    }
    return;
  }

  const prevQuestion = () => {
    setPrevBtnClicked(true);
    setGoBackToPrevQ(true);
    // if (activeStep < 1) {
    //   // if(searchParams.get("member")) {
    //   if ([...searchParams]?.length) {
    //     // navigate("/start?member=" + searchParams.get("member"), { replace: true });
    //     navigate("/start?" + searchParams, { replace: true });
    //   } else navigate("/start", { replace: true });
    // } else {
    //   setActiveStep(activeStep - 1);
    //   setPrevValue(questions?.[activeStep - 1].answer);
    // }
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
      // setPrevValue(questions?.[activeStep + 1].answer);
    } else {
      // navigateBackwardFunc();
      // navigate("/?" + searchParams, { replace: true });
      navigateBackToIntroPages();
    }
  };

  const nextQuestion = () => {

    assessCtx.updateAnswer(questions?.[activeStep].id, questions?.[activeStep].answer);
    assessCtx.updateTextAnswer(questions?.[activeStep].id, questions?.[activeStep].answerTxt);
    
    if (questions?.[activeStep]?.id == 1197) {
      let ans = questions?.[activeStep].answer || 0;
      assessCtx.updateAnswer(questions?.[activeStep].id, ans);
      assessCtx.updateTextAnswer(questions?.[activeStep].id, questions?.[activeStep].answerTxt || "");
    }
    // assessCtx.updateMonthYearAnswer(questions?.[activeStep].id, questions?.[activeStep].answerTxt);
    if (activeStep === questions?.length - 1) {
      setTimeout(() => { completeAssessment(); }, 500);
    } else if(activeStep < questions?.length - 1) {

      // Check jump logic questions


      setTimeout(() => {
        setActiveStep(activeStep + 1);
        setPrevValue(questions?.[activeStep + 1].answer);
      }, 20);
    //   // setSection(questions?.[activeStep + 1].sectionId - sectionIdOffset);
    }
    
    const addQPageId = 659;
    const detailsPageId = 730;
    if (activeStep < questions?.length - 1) {
      if (questions?.[activeStep]?.answer > 0) {
        setActiveStep((prev) => prev + 1);
      }
    } else {
      // navigateForwardFunc();
      // if (questions[activeStep]?.answer > 0 || questions[activeStep]?.answerTxt) {
      // navigateToIntroPages();
      if (questions[activeStep]?.answer > 0) {
        // navigate(`/details/${id}/${detailsPageId}?${searchParams}`, { replace: true });
        // navigate(`/details/${id}`, { replace: true });
        completeAssessment();
        // navigate(`/details/${id}?${searchParams}`, { replace: true });
      }
    }

  };

  const submitAnswer = (answer) => {
    const jsonBody = {
      pageId: pageId,
      questions: [
        {
          "id": questions?.[activeStep]?.id,
          "answer": answer,
          "answerTxt": null
        }
      ],
    }
    console.log(jsonBody);
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody)
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status === "success") {
        // navigate("/assessment/" + id + "/" + pageId + "/?" + searchParams, { replace: true });
        // save the answer option so you see the selected option when going back & forth between questions 
        questions[activeStep].answer = answer;
        if(activeStep < (questions?.length - 1)) {
            setActiveStep(activeStep + 1);
            // setPrevValue(questions?.[activeStep + 1].answer);
        } else {
          // navigate(navigatePath, { replace: true });
          // navigateForwardFunc();
          const addQPageId = 718;
          // navigate(`/assessment-additional/${id}/${addQPageId}?${searchParams}`, { replace: true });
          navigate(`/details/${id}?${searchParams}`, { replace: true });
        }
        console.log("data", data);
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => console.error("Failed to send answer details...", err));
  }

  // console.log("activeStep", activeStep);
  // console.log("orderNo", questions?.[activeStep]?.orderNo, typeof questions?.[activeStep]?.orderNo);
  const navigatePath = (pgId) => "/assessment/" + id + "/" + pgId + "/?" + searchParams;
  const navigateForwardFunc = () => {
    if(pageId === "28") {
      navigate("/charts/" + id + "/29?" + searchParams, { replace: true });
    } else if(pageId === "30") {
      navigate("/charts/" + id + "/31?" + searchParams, { replace: true });
    } else if(pageId === "32") {
      navigate("/charts/" + id + "/33?" + searchParams, { replace: true });
    } else if(pageId === "34") {
      navigate("/charts/" + id + "/35?" + searchParams, { replace: true });
    } else if(pageId === "36") {
      navigate("/charts/" + id + "/37?" + searchParams, { replace: true });
    } else if(pageId === "38") {
      navigate("/assessment-additional/" + id + "/39?" + searchParams, { replace: true });
    }
  }
  const navigateBackwardFunc = () => {
    let qOrderNum = questions?.[activeStep]?.orderNo;
    console.log("qOrderNum", qOrderNum);
    console.log("pageId", pageId, typeof pageId);
    
    if (pageId === "28") navigate("/first-form/" + id + "?" + searchParams, { replace: true }); 
    else if (pageId === "30") {
      if(qOrderNum == 2) navigate("/charts/" + id + "/29?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(28), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "32") {
      if(qOrderNum == 5) navigate("/charts/" + id + "/31?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(30), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "34") {
      if(qOrderNum == 8) navigate("/charts/" + id + "/33?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(32), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "36") {
      if(qOrderNum == 11) navigate("/charts/" + id + "/35?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(34), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "38") {
      if(qOrderNum == 14) navigate("/charts/" + id + "/37?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(36), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    } else {
      navigate("/?" + searchParams, { replace: true });
    }
  }

  const moveToNextQuestion = (answer) => {

    // questions[activeStep].answer = answer;
    setQuestions((prev) => {
      return prev.map((q, idx) => {
        if (q.id === questions?.[activeStep]?.id) {
          q.answer = answer;
        }
        return q;
      })
    })
    assessCtx.updateAnswer(questions?.[activeStep]?.id, questions?.[activeStep]?.answer);
    // assessCtx.updateTextAnswer(questions?.[activeStep]?.id, questions?.[activeStep]?.answerTxt);

    // Check jump logic questions
    // If bible variable > 3 && if believe variable > 3 then jump to /outro/

    let isJump = false;
    let jumpLocation = null;

    // Get all standalone logic (ie parent_id = 0)
    let logic = questions[activeStep]?.logic.filter(obj => obj.parent_id == 0 && obj.logic_type == "jump");
    console.log(logic);
    if(logic.length > 0) {

      for (let l = 0; l < logic.length; l++) {
        
        let currentLogic = [];
        currentLogic.push(logic[l]);
        let childLogic = questions[activeStep]?.logic.filter(obj => obj.parent_id == logic[l].id);
        currentLogic.push(...childLogic);
        console.log(currentLogic);
        
        if(currentLogic.length > 1) {
          console.log(getOperatorLogic(getQuestionBasedOnVariable(questions, questions[activeStep]?.logic[l]?.variable).answer, questions[activeStep]?.logic[l]?.operator, questions[activeStep]?.logic[l]?.value));
          let subJump = 0;
          for (let c = 0; c < currentLogic.length; c++) {
            if(getOperatorLogic(getQuestionBasedOnVariable(questions, currentLogic[c].variable).answer, currentLogic[c].operator, currentLogic[c].value)) {
              subJump++
            }
          }
          if(subJump == currentLogic.length) {
            isJump = true;
          }
        } else {
          if (getOperatorLogic(getQuestionBasedOnVariable(questions, questions[activeStep]?.logic[l]?.variable).answer, questions[activeStep]?.logic[l]?.operator, questions[activeStep]?.logic[l]?.value)) {
            isJump = true;
          }
        }
      }
    }

    
    if (answer >= 0 || answer?.trim()?.length > 0) {
      if(isJump) {
        questions.length = activeStep + 1;
        setTimeout(() => {
          // navigateToIntroPages();
          completeAssessment(isJump);
        }, 500);
      } else {
        if (activeStep < questions?.length - 1) {
          setTimeout(() => {
            console.log("This runs");
            // setPrevValue(questions?.[activeStep]?.answer);
            setActiveStep(activeStep + 1);
          }, 500);
        } else {
          // questions?.[activeStep].answer = answer;
          console.log("\n\nSUBMITTING ANSWERS\n\n");
          setTimeout(() => {
            // navigateToIntroPages();
            completeAssessment(isJump);
          }, 500);
        }
      }
      
    } else {
      // questions?.[activeStep].answer = answer;
      console.log("\n\nSUBMITTING ANSWERS\n\n");
      setTimeout(() => {
        // navigateToIntroPages();
        completeAssessment();
      }, 500);
    }
    
    console.log("answer", answer);
    console.log("\nactiveStep:", activeStep, "\nquestions?.length:", questions?.length, "\n");
    
  }
  console.log(questions[activeStep]?.logic)

  const getOperatorLogic = (operand1, operator, operand2) => {
    console.log(operand1, operator, operand2)
    if (operator == '<') {
      return operand1 < operand2
    } else if (operator == '>') {
      return operand1 > operand2
    } else if (operator == '||') {
      return operand1 || operand2
    }
  }

  const getChildOfLogicArray = (array, parentId) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].parent_id == parentId) {
        return array[i]
      }
    }
  }
  const getQuestionBasedOnVariable = (array, variable) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].variable == variable) {
        return array[i]
      }
    }
  }

  function navigateToIntroPages() {

    let qOrderNo = questions?.[activeStep]?.orderNo;
    if (qOrderNo == 7) {
      navigate("/intro/" + id + "/734?" + searchParams, { replace: true });
    } else if (qOrderNo == 14) {
      navigate("/intro/" + id + "/736?" + searchParams, { replace: true });
    } else if (qOrderNo == 21) {
      navigate("/intro/" + id + "/738?" + searchParams, { replace: true });
    }
  }

  function navigateBackToIntroPages() {
    // let qOrderNo = questions?.[activeStep]?.orderNo;
    // if (qOrderNo == 8) {
    //   navigate("/intro/" + id + "/734?" + searchParams, { replace: true });
    // } else if (qOrderNo == 15) {
    //   navigate("/intro/" + id + "/736?" + searchParams, { replace: true });
    // } else if (qOrderNo == 22) {
    //   navigate("/intro/" + id + "/738?" + searchParams, { replace: true });
    // } else if (qOrderNo == 1) {
    //   navigate("/?" + searchParams, { replace: true });
    // }
    // navigate("/before-you-start/" + id + "?" + searchParams, { replace: true });
    navigate("/?" + searchParams, { replace: true });
  }

  const completeAssessment = (isJump) => {

    setIsLoading(true);
    
    const jsonBody = { 
      pageId: pageId,
      questions: questions,
    }
    console.log(jsonBody);
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody)
    }).then(response => {
      return response.json();
    }).then(data => {
      setIsLoading(false);
      console.log('data@',data)
      if(data.status === "success") {
        const additionalpageId = 791;
        localStorage.removeItem("assessmentAnswers");
        console.log('success---')
        // if ( pageId == 739 ) {
        //   navigate(`/details/${id}?${searchParams}`, { replace: true });
        // }
        // navigate(`/outro/${id}/${additionalpageId}`, { replace: true });
        if(isJump) {
          navigate(`/outro/${id}/0`, { replace: true });
        } else {
          navigate(`/assessment-additional/${id}/${additionalpageId}`, { replace: true });
          console.log("data----", data);
        }
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => {
      console.error("Failed to send answer details...", err);
      setIsLoading(false);
    });

    // if ( pageId == 728 ) {
    //   console.log('pageId-----',pageId)
    //   navigate("/intro/" + id + "/734?" + searchParams, { replace: true });
    // }
    // else if ( pageId == 735 ) {
    //   navigate("/intro/" + id + "/736?" + searchParams, { replace: true });
    // }
    // else if ( pageId == 737 ) {
    //   navigate("/intro/" + id + "/738?" + searchParams, { replace: true });
    // }
	}

  const nextDisabled = () => {
    if (questions[activeStep].mandatory === "no") {
      return false;
    }
    if ((questions[activeStep].answer >= 0) || questions[activeStep].answerTxt?.trim()?.length > 0) {
      return false;
    } else {
      return true;
    }
  }


  const showNextButtonText = () => {

    if (activeStep === (questions?.length - 1)) {
      return <>NEXT</>;
      // } else if (activeStep === 1) {
      //   return <>
      //     {/* Next&nbsp;&nbsp;<img src={nextArrowFunc()} alt="Next Button" /> */}
      //     NEXT&nbsp;&nbsp;<img src={nextArrow} alt="Next Page Arrow" />
      //   </>;
      // }
    } else {
      return <>
        {/* &nbsp;&nbsp;<img src={nextArrowFunc()} alt="Next Button" /> */}
        {/* NEXT&nbsp;&nbsp;<img src={nextArrow} alt="Next Page Arrow" /> */}
        NEXT
      </>;
    }
  }

  const updateTextAnswer = (id, textVal) => {
    setQuestions(questions?.map((q) => {
      if (q.id === id) q.answerTxt = textVal;
      if (q.id === id) q.answer = 0;
      return q;
    }))
  }

  const updateTextAnswerAndMoveToNextQuestion = (id, textVal, ansVal, skip) => {
    setQuestions(questions?.map((q) => {
      if (q.id === id) q.answerTxt = textVal;
      if (q.id === id) q.answer = ansVal;
      return q;
    }))
    assessCtx.updateAnswer(id, ansVal);
    if (skip) {
      if (activeStep === questions?.length - 1) {
        setTimeout(() => { completeAssessment(); }, 500);
      } else if(activeStep < questions?.length - 1) {
        setTimeout(() => {
          setActiveStep(activeStep + 1);
          setPrevValue(questions?.[activeStep + 1].answer);
        }, 500);
      }
    }
  }

  console.log("questions", questions);

  const getQuestionsPerSection = () => {
    let arr = questions?.map(q => q.sectionId);
    let count = {};
    arr?.forEach(element => {
      count[element] = (count[element] || 0) + 1;
    });
    return Object.values(count);
  }
  
  const questionsPerSection = getQuestionsPerSection();
  // const questionsPerSection = [8, 8, 9, 10];
  // console.log("questionsPerSection", questionsPerSection);
  // console.log("currentProgress", activeStep);

  // const toggleSkipQuestionCheckbox = (step) => {
  //   // we are not really skipping the question, we are answering it with answer 0
  //   // e.preventDefault();
  //   const currentSkipState = assessCtx.questions?.[step]?.skip || false;
  //   const updatedSkipState = !currentSkipState;
  //   setQuestions((prev) => {
  //     const updatedQuestions = [...prev];
  //     updatedQuestions[step].skip = updatedSkipState;
  //     return updatedQuestions;
  //   })
  //   assessCtx.skipQuestion(questions?.[step]?.id, updatedSkipState);
  //   // only skip question if skip is set to true
  //   if (updatedSkipState) {
  //     moveToNextQuestion(0);
  //   }
  // };

  const getStepContent = (step) => {
    return (
      <Question question={questions?.[step]} questionCount={questionCount} questionAnswered={moveToNextQuestion} currentValue={prevValue} updateTextAnswer={updateTextAnswer} updateTextAnswerAndMoveToNextQuestion={updateTextAnswerAndMoveToNextQuestion} />
    );
  };

  if (isLoading) {
		return <div className="container assessment loading"><CircularProgress style={{ color: "#EF8933" }} size={60} /></div>
	}

  

  return (
    <>
      <div className="container assessment">
        <div className="section-progress-top">
          <ProgressBarSingle currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} totalNumOfQuestions={questions?.length} />
          {/* <p className="current-section">{currentSectionName}</p> */}
          {/* <ProgressBar currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} questionsPerSection={questionsPerSection} sections={sections} count={questionCount} currentSectionName={currentSectionName} /> */}
        </div>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep}></Stepper>
        </Box>
        <div>
          <div className="steps-container">
            <div>
              <div className="steps">

                { windowWidth > 830 && <div className="spacer"></div>}              
                {/* <div className="progress-indicator"><strong>{activeStep + 1}</strong>/{questionCount}</div> */}
                {getStepContent(activeStep)}
                
              </div>
              <div className="step-buttons">
                <Button variant="prev" className="prev-btn" onClick={prevQuestion}><img  src={prevIcon} alt="prev button" /></Button>
                {showNextBtn ? <Button variant="next" className="next-btn" onClick={nextQuestion} disabled={nextDisabled()}>{showNextButtonText()}</Button> : <></> }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AssessmentV3;

